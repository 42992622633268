<div class="attrs-content" style="position: relative;" (window:resize)="onResize($event)" [ngClass]="{'disabled':params.disabled}">

  <template #popupAttributteContainer>
          
  </template>

  <div class="attributes-container">
    <div *ngFor="let ele of list;  index as i;" class="attribute-capacity" (click)="!params.disabled && openPopup( $event , ele, i )" title="{{ele}}"
    [ngStyle]="{'background-color': params.color}">
      <span class="text-label">{{ ele }}</span>
      <div class="close-container">
          <i class="fa fa-times" aria-hidden="true"></i>
          <i class="fa fa-trash" aria-hidden="true" style="cursor: pointer;"  (click)="!params.disabled && removeE($event , ele, i)"></i>
      </div>
    </div>
  </div>

  <div class="plus-container" [ngClass]="{'disabled': disableAddAttr()}" (click)="!params.disabled && openPopup( $event,'' )">
    <i class="fa fa-plus-circle" aria-hidden="true" style="color:#00AEEF; font-size: 28px;"></i>
  </div>
              
</div>