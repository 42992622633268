import { Component, OnInit, EventEmitter, Output, Input, ComponentRef, 
  ViewChild, ViewContainerRef, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { AppService } from '@core/services/app.service'
import { ElementRef } from "@angular/core";
import { ButtonListPopupComponent } from './button-list-popup/button-list-popup.component'

@Component({
  selector: 'button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss']
})
export class ButtonListComponent implements OnInit {

  @Input() list: any[];
  @Input() element: any;
  @Input() params: any = {};
  @Output() open = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() new = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();

  componentRef: ComponentRef<ButtonListPopupComponent>;
  @ViewChild("popupAttributteContainer", { read: ViewContainerRef }) container;

  popupPosition:any;
  error:string="";    

  constructor(private appService:AppService, private resolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    
    if(this.params.popup === undefined)
      this.params.popup = true;
    if(this.params.color === undefined)
      this.params.color = "#ddd";
    if(this.params.removeItem === undefined)
      this.params.removeItem = true;
    if(this.params.disabled === undefined)
      this.params.disabled = false;
  }

  removeE(event, element:any, index:number){

    event.stopPropagation();    

    if(this.params.removeItem)
      this.appService.removeFromArray(this.list, element)
      
    this.remove.emit({element:element, index:index});
   
  }

  openPopup( event: MouseEvent , element:any, index:number = -1) : void {

    event.preventDefault();        

    //if(this.disableAddAttr() && !attributeCapacity)
      //return;    

    if(this.params.popup)  
    {      

      let position:any={left:0,top:0};
      let diff = 266 / 2 -  event.target["offsetWidth"] / 2;
      
      console.log("layerX -> " + event["layerX"])
      console.log("offsetX -> " + event["offsetX"])
      position.left = event["layerX"] - event["offsetX"] - diff;    
      position.top = event["layerY"] - event["offsetY"] + event.target["offsetHeight"] + 5
      position.top = event["clientY"] - event["offsetY"] + event.target["offsetHeight"];                              

      let open = true;    
      //if(typeof this.componentRef != 'undefined')    
        //open = this.componentRef.hostView.destroyed;  
      if(open){              
        let position = {left:0,top:0};
        let diff = 266 / 2 -  event.target["offsetWidth"] / 2;
        
        position.left = event["layerX"] - event["offsetX"] - diff;    
        position.top = event["layerY"] - event["offsetY"] + event.target["offsetHeight"] + 5
        //position.top = event["clientY"] - event["offsetY"] + event.target["offsetHeight"];
        this.popupPosition = position;        
        this.createPopup(element, index)
      }
      else console.log("Popup is open")
    }
    else
    {
      let ev = {element:element, index:index};
      if(!element)
        ev["new"] = true;
      this.open.emit(ev);      
    }
  }
  
  onResize(event) {
        
    if(this.params.popup)
      this.container.clear();

  }

  createPopup(element:any, index:number) {
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(ButtonListPopupComponent);
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.position = this.popupPosition;
    this.componentRef.instance.list = this.list;
    this.componentRef.instance.element = element;
    this.componentRef.instance.index = index;
    
    if(index != -1)
      this.componentRef.instance.createMode = false;
  
    this.componentRef.instance.onClose.subscribe(data => {        
      this.container.clear();
    });

    this.componentRef.instance.onSave.subscribe(data => {      

      this.container.clear();

      if(data["index"]!== -1)              
      {
        this.list[data["index"]] = data["element"];
        this.update.emit({element:element, index:index});
      }
      else
      {
        this.list.push(data["element"]);
        this.new.emit({element:element});
      }            

    });
    
  }

  getAttributeById(id){
    return true;
  }

  getDefaultAttribute() : any{
    return true;
  }

  disableAddAttr(){        
    /*if(this.attributesCapacity.length == this.dataService.attributes.length)
      return true;
    else*/
      return false;      
  }
}
