<div *ngIf="message" class="alert alert-info">{{message}}</div>
<div *ngIf="error" class="alert alert-danger">{{error}}</div>
<div *ngIf="updateError" class="alert alert-danger">{{updateError}}</div>

<h5 class="mt-3 mb-4">Actions</h5>

<div class="row">
  <div class="col">
    
    <json-editor #JsonEditorComponent1 [options]="editorOptions" [data]="actions" (change)="getData($event)"></json-editor>
    
	</div>
</div>

<div class="row mt-3 mb-3">
	<div class="input-group col-md-2">
		<button [disabled]="!changed" (click)="saveActions()" class="btn btn-primary"> Save</button>		
	</div>
</div>

<!--
<div *ngIf="messageLists" class="alert alert-info">{{messageLists}}</div>
<div *ngIf="errorLists" class="alert alert-danger">{{errorLists}}</div>
<div *ngIf="updateErrorLists" class="alert alert-danger">{{updateErrorLists}}</div>

<h5 class="mt-3 mb-4">Lists</h5>
<div class="row">
  <div class="col">
  
    <json-editor style="min-height: 700px;" #JsonEditorComponent2 [options]="editorOptions2" [data]="lists" (change)="getData2($event)"></json-editor>

	</div>
</div>

<div class="row mt-3 mb-3">
	<div class="input-group col-md-2">
		<button [disabled]="!changedLists" (click)="saveLists()" class="btn btn-primary"> Save</button>		
	</div>
	
</div>-->