<div class="container">
    <div *ngIf="errorGet" class="alert alert-danger" role="alert">
        {{errorGet}} <i class="fa fa-refresh" aria-hidden="true" ></i>
    </div>
    <br>  
    <div *ngIf="error" class="alert alert-danger" role="alert">
        {{error}} 
    </div>
    <h4 class="mb-3" class="page-title"> Import RuleSet</h4>

		<div style="border-bottom: 1px solid #dee2e6;"></div>
		<div class="modal-body">
			<div class="row">
					<div class="col">
		
						<table class="table borderless">
							<thead class="thead-light">
								<tr>				
									<th id="tmp" style="width:200px"></th>
									<th id="tmp1">Rule</th>                
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let importedRule of importedRuleList" class="selectable" (click)="clickRule(importedRule)">
												<!-- rule | json -->												
									<td>
										<input type="checkbox" [(ngModel)]="importedRule.selected" (change)="changeSelRule()"/>
									</td>
									<td>
										{{ importedRule.rule.name }}
									</td>												
								</tr>
							</tbody>
						</table>
					</div>
			</div>
		</div>

		<div class="modal-footer">			
			
			<span>&nbsp;</span>
			<div>
				<button style="margin: 0.25rem;" type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button> 
				<button type="submit" class="btn btn-primary" [disabled]="" (click)="accept()">Save</button>
			</div>
		</div>    

</div>
