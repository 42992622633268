import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '@core/services/app.service';
import { Template } from '@dashboard/models/template';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

apiSegment:string = "template";

  constructor(private http:HttpClient, private appService:AppService) { }

  getAll(): Observable<any> {											
		return this.http.get(this.appService.getApiBaseUrl() + this.apiSegment);
  }
  
  create(template:Template): Observable<any> {				    
		return this.http.post(this.appService.getApiBaseUrl() + this.apiSegment, template);
	}

	update(template:Template): Observable<any> {						
		return this.http.put(this.appService.getApiBaseUrl() + this.apiSegment + "/" +  template.templateId, template);
  }
  
  delete(template:Template): Observable<any> {				
		return this.http.delete(this.appService.getApiBaseUrl() + this.apiSegment + "/" + template.templateId);
  }

  replaceEle(templates, templateId, template){		
		
		for(let i=0; i < templates.length; i++){
		  if(templates[i].templateId ==templateId)
		  {			
			  templates[i] = template;
			  break;
		  }
		}
	}
}
