import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter , Input, AfterViewInit} from '@angular/core';
import { Validator } from '@shared/interfaces/validator'
import { NotBlank } from '@shared/classes/not-blank'

export interface InputEditableParams{
	value?:any,
  onlyInteger?:boolean,
  width?:string,
  height?:string
  disabled?:boolean
}

@Component({
  selector: 'input-editable',
  templateUrl: './input-editable.component.html',
  styleUrls: ['./input-editable.component.scss']
})

export class InputEditableComponent implements OnInit, AfterViewInit {

	@Input() params:InputEditableParams;
  @Input() iniFocus:boolean = false;
  @Input() validators:Validator[] = [];
	
	editing:boolean = false;
	@ViewChild('inputValue') inputOne: ElementRef;  
	@Output() onSave = new EventEmitter<any>();
  @Output() onError = new EventEmitter<Validator>();

  inputId:string = "";
	count:number=0;
  constructor(){}
  oldValue:any;

  ngOnInit(): void {    			

    if(!this.params.disabled && this.params.disabled!==false)
      this.params.disabled = false;    

    if(!this.params)
			this.params = {value:0, onlyInteger: false, width:"40px", height:"24px", disabled: false};      

    this.oldValue = this.params.value;    
    this.inputId = this.getUniqueId(4);
    
  }

  ngAfterViewInit() {            
    setTimeout(()=> {if(this.iniFocus) this.edit()}, 50)
  }

	edit(){
		setTimeout(()=> {
			const inputElem = <HTMLInputElement>this.inputOne.nativeElement;			
			inputElem.focus();      
			//inputElem.addEventListener("blur", (ev) => {self.save(); console.log("input-editable blur");console.log(inputElem)} )
		});
		
		this.editing = true;
	}

  onBlur(){    
    console.log("On blur " + this.inputId)
    this.save();    
  }
  getUniqueId(parts: number): string {
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

	save(){

		if(this.editing && (this.params.value!== this.oldValue || !this.params.value))
    {
			
      if(this.validators.length > 0)
      {
        let valid = true;

        for(let i=0; i < this.validators.length; i++){

          if(!this.validators[i].validate(this.params.value))
          {
            valid = false;
            this.params.value = this.oldValue;
            this.onError.emit(this.validators[i]);
            break;
          }

          if(valid)
          {
            this.oldValue = this.params.value;
            this.onSave.emit(this.params.value);
          }

        }
      }
      else
      {
        this.oldValue = this.params.value;
        this.onSave.emit(this.params.value);			        
      }
      
		}  

    this.editing = false;

	}

	keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;

    if(charCode == 13)
    {          			
			this.save();
      event.preventDefault();
      return false;
    }
		if(!this.params.onlyInteger)
			return true;
		
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
		}
		else 
    	return true;		
		
  }

}
