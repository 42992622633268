import { Condition } from "./condition";
import { ConditionNode } from './condition-node'

export class ConditionNodesParent implements ConditionNode{
  conditionType:string;  
  conditionNodes:any[] = [];
  isRoot:boolean = false;    

  constructor(conditionType:string = "all"){    
    this.conditionType = conditionType;
  }
}