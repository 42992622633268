<div class="row">
  <div class="col py-2 pb-3">
              
    <select class="form-control mr-3" style="width: auto;display: inline;" name="typeCondition" [(ngModel)]="conditionNodesParent.conditionType">
      <option value="all">All</option>
      <option value="any">Any</option>
      <option value="none">None</option>
    </select>

    <span>of the following rules:</span>
    <button class="btn btn-sm btn-secondary ml-3 mt-3" (click)="addCondition()"><i class="fa fa-plus"></i> Condition</button>
    <button class="btn btn-sm btn-secondary ml-3 mt-3" (click)="addSubCondition()"><i class="fa fa-plus"></i> SubCondition</button>
    <button *ngIf="!conditionNodesParent.isRoot" class="btn btn-sm btn-danger ml-3 mt-3" (click)="deleteSubCondition(conditionNodesParent)">
      <i class="fa fa-trash-o"></i> SubCondition
    </button>
  </div>
</div>

<div *ngFor="let condition of conditionNodesParent.conditionNodes" class="row">
  <div *ngIf="isCondition(condition)" class="col-3 py-2 pb-2 pl-5">
    <select class="form-control" [(ngModel)]="condition.name">
        <option *ngFor="let field of conditionFields" [value]="field.name" >{{field.label}}</option>
    </select>
  </div>
  
  <div *ngIf="isCondition(condition)" class="col-4 py-2 pb-2">

    <select class="form-control" [(ngModel)]="condition.operator" (change)="changeConditionOperator(condition)">
      <option value="present">is present</option>
      <option value="blank">is blank</option>
      <option value="equalTo">is equal to</option>
      <option value="notEqualTo">is not equal to</option>
      <option value="includes">includes</option>
      <option value="matchesRegex">matches regex</option>
    </select>
  </div>
  <div *ngIf="isCondition(condition)" class="col-3 py-2 pb-2">
    <input class="form-control" type="text" [(ngModel)]="condition.value" [disabled]="condition.operator == 'present' || condition.operator == 'blank'"/>
  </div>
  <div *ngIf="isCondition(condition)" class="col-2 py-2 pb-2">
    <i title="Delete" style="cursor: pointer;vertical-align: middle;" class="fa fa-trash-o" (click)="deleteCondition(condition)"></i>
  </div>

  <div *ngIf="!isCondition(condition)" class="col-12 py-2 pb-2 pl-5">
    <conditions-set [conditionNodesParent]="condition" [rule]="rule" [conditionFields]="conditionFields"></conditions-set>
  </div>
    
</div>