import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { AppService } from '@core/services/app.service'
import { catchError, map, tap, debounce } from 'rxjs/operators'
import { ListElement } from '@dashboard/models/list-element'
import { ConfigAction } from '@dashboard/models/config-action'

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  apiSegment:string = "dictionary";

  constructor(private http:HttpClient, private appService:AppService) { }

  get(): Observable<any> {
		return this.http.get(this.appService.getApiBaseUrl() + "dictionary");
  }

  update(data:any): Observable<any> {			
		return this.http.put(this.appService.getApiBaseUrl() + "dictionary" , data);
  }

  getActions(): Observable<any> {
		return this.http.get(this.appService.getApiBaseUrl() + "actions");
  }

  getActionsNEW(): Observable<any> {
		return this.http.get(this.appService.getApiBaseUrl() + "actions").pipe(
      map((data: any) => {							
              
       return this.extractActionsFromDictionary(data);
        
        
      }),catchError( error => {
        console.error(error)
        return throwError( 'Something went wrong!' );
      })
    );
  }

  updateActions(data:any): Observable<any> {			
		return this.http.put(this.appService.getApiBaseUrl() + "actions" , data);
  }

  updateActionsNEW(actions:ConfigAction[]): Observable<any> {			

    let actionsDB = {action:[]};

    for(let i=0; i < actions.length; i++)
      actionsDB.action.push({name:actions[i].name, label:actions[i].label, fields:actions[i].fields})    
    
		return this.http.put(this.appService.getApiBaseUrl() + "actions" , actionsDB);
  }

  getLists(): Observable<any> {
		return this.http.get(this.appService.getApiBaseUrl() + "lists").pipe(
      map((data: any) => {							                

        return this.extractListsFromDictionary(data);      
        
      }),catchError( error => {
        console.error(error)
        return throwError( 'Something went wrong!' );
      })
    );
  }

  extractListsFromDictionary(data){
    
    let lists:ListElement[] = [];
    let listsDB = data.list;

    for(let key in listsDB) {
      let listEle = new ListElement();
      listEle.values = [];
      listEle.name = key;
      
      for(let j=0; j < listsDB[key].length; j++){
        listEle.values.push(listsDB[key][j])
      }

      lists.push(listEle)
    }
    
    return lists;

  }

  extractActionsFromDictionary(data){

    let configActions:ConfigAction[] = [];
    let conActionDB = data.action;

    for(let i=0; i < conActionDB.length; i++) {
      let obj = conActionDB[i];
      
      let configAction = new ConfigAction();
      
      configAction.name =obj["name"];
      configAction.label =obj["label"];
      configAction.fields =obj["fields"];            
      configAction.fieldsNames = configAction.fields.map(a => a.label);    
      configActions.push(configAction)

    }
    
    console.log("config actions--")
    console.log(configActions)

    return configActions;

  }

  getListsOLD(): Observable<any> {
    return this.http.get(this.appService.getApiBaseUrl() + "lists");
  }

  updateLists(lists:ListElement[]): Observable<any> {			

    let listsDB = {list:{}};
    
    for(let i=0; i < lists.length; i++){
      listsDB.list[lists[i].name] = lists[i].values;
    }

		return this.http.put(this.appService.getApiBaseUrl() + "lists" , listsDB);
  }

  updateListsOLD(data:any): Observable<any> {			    
		return this.http.put(this.appService.getApiBaseUrl() + "lists" , data);
  }

  getList(dictionary:any, listName:string){
    let list:any[] = [];

    if(dictionary.list[listName])
      list = dictionary.list[listName];
      
    return list;

  }

}
