import { Component, OnInit } from '@angular/core'
import { RuleSet } from '@dashboard/models/rule-set'
import { Rule } from '@dashboard/models/rule'
import { SetupRuleComponent } from '@dashboard/components/ruleset/setup-rule/setup-rule.component'
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { RuleSetService } from '@dashboard/services/rule-set.service'
import { ImportRuleSetComponent } from '@dashboard/components/ruleset/import-rule-set/import-rule-set.component'
import { AppService } from '@core/services/app.service'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-rules-designer',
  templateUrl: './rules-designer.component.html',
  styleUrls: ['./rules-designer.component.scss']
})
export class RulesDesignerComponent implements OnInit {

  errorGet:string = "";
  error:string = "";
  selRuleSetName:string;
  selRuleSet:RuleSet;
  selRuleSetBCK:RuleSet;
  rules:Rule[];
  fileName: string = "";
  bab:boolean = true;
  ruleSetNames:string[] = [];
  ruleSets:RuleSet[] = [];
  pendingChanges:boolean = false;

  constructor(private modalService: NgbModal, private ruleSetService:RuleSetService, private appService:AppService) { }

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker'
  ];

  drop(event: CdkDragDrop<string[]>) {
    console.log("event ->", event)

    //let rule:Rule = this.selRuleSet.ruleList[event.previousIndex]
    //console.log("select rule ->", rule)
    //this.moveRule(rule, event.currentIndex)
    this.moveRuleByIndex(event.previousIndex, event.currentIndex)

    //moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  }

  ngOnInit(): void {
    
    this.selRuleSetBCK = new RuleSet();
    this.loadRuleSets();

  }
  loadRuleSets(){

    this.error = "";
    this.ruleSetNames = [];

    this.selRuleSet = new RuleSet();

    this.ruleSetService.getAll().subscribe((res) => { 
      
      this.ruleSets = res;
      this.selRuleSet = this.ruleSets[0];           
      this.makeBackup();
      
		},(error)=>{      
			this.error = "An error has occurred. Try again ";
    });        

  }

  makeBackup(){
    this.ruleSetService.cloneRuleSet(this.selRuleSet, this.selRuleSetBCK);
  }

  changeRuleSet(){
    this.makeBackup();
  }

  updateRuleSet(callback = () => {}){

    this.ruleSetService.update(this.selRuleSet).subscribe((res) => {       
      
      //this.ruleSetService.cloneRuleSet(this.selRuleSet, this.selRuleSetBCK);
      //this.pendingChanges = false;

      callback();
		},(error)=>{
      
			this.error = "An error has occurred. Try again ";
    });

  }

  changeStopEvalWhenMatched(){
    //this.updateRuleSet();
    this.pendingChanges = true;
  }

  saveField($event, rule:Rule, field:string){

    let oldVal:string = rule[field];
        
    if(field == "name")
    {        
      let newName = !$event && rule.isNew ? "New rule" : $event;
      console.log("Initial newName=" + newName);

      let obj = {}        
      let list = this.selRuleSet.ruleList.map(item => item.name);
      this.appService.checkExistsItem(obj, list, newName);

      console.log("last new name->" + JSON.stringify(obj))
      rule[field] = obj["name"];
              
      let found = false;
      let arrOrder = this.selRuleSet.ruleOrder.split(",");
      
      for(let i=0; i < arrOrder.length; i++){

        if(arrOrder[i] == oldVal)
        {
          arrOrder[i] = rule[field];
          found = true;
        }

      }

      if(!found)
        arrOrder.push(rule[field])
      
      this.selRuleSet.ruleOrder = arrOrder.join(",");
      //this.updateRuleSet();
      this.pendingChanges = true;
      
    }
    else{
      rule[field] = $event;
      //this.updateRuleSet();
      this.pendingChanges = true;
    }

  }

  undo(){
    
    this.pendingChanges = false;
    this.ruleSetService.cloneRuleSet(this.selRuleSetBCK, this.selRuleSet);

  }

  setupRule(rule:Rule){
    const modalRef = this.modalService.open(SetupRuleComponent,  { windowClass : "setup-rules-modal"});

    modalRef.componentInstance.rule = rule;
    modalRef.componentInstance.ruleSet = this.selRuleSet;
  }

  openImportRulesetModal(ruleSet:RuleSet){
    const modalRef = this.modalService.open(ImportRuleSetComponent);
    modalRef.componentInstance.ruleSet = this.selRuleSet;
    modalRef.componentInstance.importedRuleSet = ruleSet;
    this.error = "";
  }

  addRule(){
    let rule:Rule = new Rule();
    rule.isNew = true;
    this.selRuleSet.ruleList.push(rule);
  }

  moveUp(rule:Rule){
    this.moveRule(rule, -1);    
  }

  moveDown(rule:Rule){    
    this.moveRule(rule, +1);
  }

  moveRule_old(rule:Rule, toIndex){

    console.log("Rule order", this.selRuleSet.ruleOrder)    
    let rulesNameOrdered = this.selRuleSet.ruleOrder.split(",");
    console.log("rulesNameOrdered", rulesNameOrdered)    

    let index = rulesNameOrdered.indexOf(rule.name)


    console.log("current rule name ->" + rule.name)
    console.log("move index " + index + " to index " + (index - 1))
    let orderedArr:any[] = this.appService.arrayMove(rulesNameOrdered, index, (index + toIndex))
    console.log(orderedArr)

    this.selRuleSet.ruleOrder = orderedArr.join(",");
    this.appService.arrayMove(this.selRuleSet.ruleList, index, (index + toIndex))

    rule.moved = true;

    setTimeout(()=>rule.moved = false, 5000);

    //this.updateRuleSet();
    this.pendingChanges = true;

  }

  moveRule(rule:Rule, toIndex){

    console.log("Rule order", this.selRuleSet.ruleOrder)    
    let rulesNameOrdered = this.selRuleSet.ruleOrder.split(",");
    console.log("rulesNameOrdered", rulesNameOrdered)    

    let index = rulesNameOrdered.indexOf(rule.name)


    console.log("current rule name ->" + rule.name)
    console.log("move index " + index + " to index " + (toIndex))
    let orderedArr:any[] = this.appService.arrayMove(rulesNameOrdered, index, (toIndex))
    console.log(orderedArr)

    this.selRuleSet.ruleOrder = orderedArr.join(",");
    this.appService.arrayMove(this.selRuleSet.ruleList, index, (toIndex))

    rule.moved = true;

    setTimeout(()=>rule.moved = false, 5000);

    //this.updateRuleSet();
    this.pendingChanges = true;

  }

  moveRuleByIndex(index:number, toIndex){

    console.log("Rule order", this.selRuleSet.ruleOrder)    
    let rulesNameOrdered = this.selRuleSet.ruleOrder.split(",");
    console.log("rulesNameOrdered", rulesNameOrdered)    

    console.log("move index " + index + " to index " + (toIndex))
    let orderedArr:any[] = this.appService.arrayMove(rulesNameOrdered, index, (toIndex))
    console.log(orderedArr)

    this.selRuleSet.ruleOrder = orderedArr.join(",");
    this.appService.arrayMove(this.selRuleSet.ruleList, index, (toIndex))
      
    this.pendingChanges = true;

  }

  importRuleset(evt): void {

    this.fileName = evt.target.files[0].name;            
    let file = evt.target.files[0];

    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = function(x) {      
      let res:string = fileReader.result as string;
      try{
        let obj: any = JSON.parse(res);
        
        if(self.ruleSetService.checkValidRuleSet(obj))
        {
          let ruleSet:RuleSet = self.ruleSetService.parseRuleSet(obj);          
          self.openImportRulesetModal(ruleSet);          
        }
        else
          self.error = "Incorrect ruleSet format";

      }catch(e){console.log(e); self.error = "Incorrect File json format"}
    }
    fileReader.readAsText(file);
  }

  pendingRulesToSave(){

    let count:number = 0;

    //console.log("pendingRules", this.selRuleSet.ruleList)

    for(let i=0; i < this.selRuleSet.ruleList.length; i++){
      if(this.selRuleSet.ruleList[i].pendingToSave)
        count++;
    }
    
    return count;
  }

  savePendingRules(){
    
    this.updateRuleSet(() => {
      for(let i=0; i < this.selRuleSet.ruleList.length; i++){
        if(this.selRuleSet.ruleList[i].pendingToSave)
          this.selRuleSet.ruleList[i].pendingToSave = false;

          this.selRuleSet.ruleList[i].isNew = false;
      }

    })
    
  }

  discardPendingRules(){

    for(let i=0; i < this.selRuleSet.ruleList.length; i++){
      if(this.selRuleSet.ruleList[i].pendingToSave)
        this.selRuleSet.ruleList[i].pendingToSave = false;
    }

    this.loadRuleSets();
  }

  exportRuleSet(){
    
    let ruleSetDB = this.ruleSetService.prepareToDB(this.selRuleSet);
    let text:string = JSON.stringify(ruleSetDB, null, 2);
    let filename:string = this.selRuleSet.ruleSetName + ".json";
    this.appService.saveFile(text, filename);

  }
}
