import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;

  constructor(private http: HttpClient) { }

  loadAppConfig() {

    let env:string;

    environment.production ? env = "prod" : env = "dev";

    const configFilePath = './assets/config/config_' + env + '.json';

    console.log("configFilePath->" + configFilePath)

    return this.http.get(configFilePath)
      .toPromise()
      .then(data => {        
        this.appConfig = data;
        console.log("ENVIRONMENT [" + environment.production + "]")        
      });
  }

  // This is an example property ... you can make it however you want.
  getConfig() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig;
  }
}
