import { Component, OnInit } from '@angular/core'
import { ConditionField } from '@dashboard/models/condition-field'
import { ConfigRuleSetService } from '@dashboard/services/config-rule-set.service'
import { RuleSetService } from '@dashboard/services/rule-set.service'
import { Condition } from '@dashboard/models/condition'
import { Validator } from '@shared/interfaces/validator'
import { NotBlank } from '@shared/classes/not-blank'
import { AppService } from '@core/services/app.service'

@Component({
  selector: 'conditions-fields',
  templateUrl: './conditions-fields.component.html',
  styleUrls: ['./conditions-fields.component.scss']
})
export class ConditionsFieldsComponent implements OnInit {

  fields:ConditionField[] = [];
  message:string = "";
  error:string = "";
  updateError:string = "";
  usedConditions:Condition[]=[];
  nameValidators:Validator[] = [];
  labelValidators:Validator[] = [];
  disabled:boolean = false;

  constructor(private configRuleSetService:ConfigRuleSetService, private ruleSetService:RuleSetService, private appService:AppService) { }

  async ngOnInit() {            

    this.loadFields();
    this.usedConditions = await this.ruleSetService.getUsedConditions(); 

  }
  
  loadFields(){
    
    this.error = "";
    this.fields = [];
    this.disabled = false;

    this.configRuleSetService.get().subscribe((res) => {
               
      for(let i=0; i < res.fields.length; i++){

        let field = new ConditionField();
        field.name = res.fields[i].name;
        field.label = res.fields[i].label;
        field.email = res.fields[i].email;
        field.chat = res.fields[i].chat;
  
        this.fields.push(field);
      }

      this.nameValidators.push(new NotBlank())
      this.labelValidators.push(new NotBlank())

    },(error)=>{      
      this.error = "An error has occurred. Try again ";
    });

  }

  loadFieldsFromDictionary(dictionary:any){

    var ret = dictionary.condition;
    for(let attributename in ret){
        delete(ret[attributename].operators);
        if(!ret[attributename].hasOwnProperty('chat')){
            ret[attributename].chat = true;
        }
        if(!ret[attributename].hasOwnProperty('email')){
            ret[attributename].email = true;
        }
    }

    this.fields = [];

    for(let i=0; i < ret.length; i++){

      let fieldDB = ret[i];      
      let field = new ConditionField();
      field.name = fieldDB["name"];
      field.label = fieldDB["label"];
      field.email = fieldDB["email"];
      field.chat = fieldDB["chat"];

      this.fields.push(field);
      
    }
    

  }

  onErrorEditName(validator:Validator, field:ConditionField){

    if(field.isNew && validator instanceof NotBlank)          
      this.appService.removeFromArray(this.fields, field);
    else
      this.updateError = validator.errorMessage;
      
  }

  onErrorEditLabel(validator:Validator, field:ConditionField){    
    this.updateError = validator.errorMessage;    
  }
  
  saveField($event, field, name){
    
    if(name == "name")
    { 
      
        this.updateError = "";
        field.updateError = false;
                      
        let found = false;

        for(let i=0; i < this.fields.length; i++){
          if($event == this.fields[i].name){
            found = true;
            break;
          }
        }

        if(!found)
        {
          field[name] = $event;        
          this.update();  
        }
        else
        {
          this.updateError = "Error. Cant update beacause field already exsits";
          //this.loadFields();
          field.updateError = true;
        }                                                          

    }
    else{
      field[name] = $event;        
      this.update(); 
    }
    
  }

  update(){

    let fieldsDB:any[] = this.fields.map(item => {
      item.isNew = false;
      return {name:item.name, label:item.label, chat: item.chat, email: item.email}
    })
    
    this.configRuleSetService.update(fieldsDB).subscribe((res) => {
      
		},(error)=>{
      
			this.error = "An error has occurred. Try again ";
    });
  }

  searchConditionName(name){

    for(let i=0; i < this.usedConditions.length; i++){
      if(this.usedConditions[i].name == name)
        return this.usedConditions[i];
    }

    return false;
  }

  existsConditionName(name){
    if(this.searchConditionName(name))
      return true;
    else
      return false;
  }
  
  addField(){
    let field = new ConditionField();
    field.isNew = true;
    this.fields.push(field);
  }

  async remove(field:ConditionField){
              
    if (confirm("Are you sure you want to delete this item?")) {
      
        this.usedConditions = await this.ruleSetService.getUsedConditions(); 

        if(!this.searchConditionName(field.name))
        {          
          this.appService.removeFromArray(this.fields, field);
          this.update();
        }
        else this.error = "Can't delete this item beacuse already exists"
      
    }      
                
  }
}
