import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service'

@Injectable({
  providedIn: 'root'
})
export class AppService {

  serverError:string = "Han errror has ocurred";
  
  constructor(private appConfigService:AppConfigService) { }

  deepCopy(sourceObject, destinationObject) {

    for(let key in destinationObject)
      delete destinationObject[key];    
    
    for(let key in sourceObject) {
    
        if(typeof sourceObject[key] == "object") {                                  

          if(Array.isArray(sourceObject[key]))          
            destinationObject[key] = [];          
          else
            destinationObject[key] = {};
                                    
          this.deepCopy(sourceObject[key], destinationObject[key]);
        } else {
          destinationObject[key] = sourceObject[key];
        }
    }
  }

  getApiBaseUrl(){

    let config = this.appConfigService.getConfig()    
    return config.base + "/" + config.api + "/";
    
  }

  saveFile(text:string, filename:string){
    
    let a = document.createElement('a');
    a.setAttribute('href', 'data:text/plain;charset=utf-u,'+encodeURIComponent(text));
    a.setAttribute('download', filename);
    a.click()

  }

  checkExistsItem(obj:any, list:any[], defaultName:string = "New", i = 0){
    
    for(let j=0; j < list.length; j++){

      let newName:string = i == 0 ? defaultName : defaultName + "(" + i + ")";
      obj.name = newName;

      console.log(i + " , ruleName->" + list[j] + " , newName->" + newName)

      if(list[j] == newName){
        i++;
        this.checkExistsItem(obj, list, defaultName, i);
        break;
      }
    }

  }

  removeFromArray(array:any[], item:any){

    for(let i=0; i < array.length; i++){
      if(array[i] == item)
      {
        array.splice(i, 1);
        break;
      }
    }
    
  }

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };
}
