import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  private dictionary: any;

  constructor(private http: HttpClient) { }

  loadDictionary() {

    let env:string;

    const dictionaryFilePath = './assets/config/dictionary.json';

    console.log("dictionaryFilePath->" + dictionaryFilePath)

    return this.http.get(dictionaryFilePath)
      .toPromise()
      .then(data => {        
        this.dictionary = data;        
      });
  }

  // This is an example property ... you can make it however you want.
  getDictionary() {

    if (!this.dictionary) {
      throw Error('Config file not loaded!');
    }

    return this.dictionary;
  }
}
