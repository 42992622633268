import { Component, OnInit } from '@angular/core';
import { DictionaryService } from '@dashboard/services/dictionary.service'
import { ListElement }  from '@dashboard/models/list-element'
import { RuleSetService } from '@dashboard/services/rule-set.service'
import { RuleSet } from '@dashboard/models/rule-set'
import { Validator } from '@shared/interfaces/validator'
import { NotBlank } from '@shared/classes/not-blank'
import { AppService } from '@core/services/app.service'

@Component({
  selector: 'lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit {

  changed:boolean = false;
  updateError:string = "";
  error:string = "";
  message:string = "";
  lists:ListElement[] = [];
  ruleSets:RuleSet[] = [];
  nameValidators:Validator[] = [];
  usedLists:string[] = [];
  configActions:any[] = [];
  skillsParams:any = {removeItem:false}
  disabledAll:boolean = false;
  
  constructor(private dictionaryService:DictionaryService, private ruleSetService:RuleSetService, private appService:AppService) { }

  ngOnInit(){

    this.loadLists();

  }

  disabled(){

    this.disabledAll = true;
    this.skillsParams.disabled = this.disabledAll;

  }

  enabled(){

    this.disabledAll = false;
    this.skillsParams.disabled = this.disabledAll;

  }

  async loadLists(){

    this.error = "";
    this.skillsParams.disabled = false;
    this.enabled();
    
    try{

      this.ruleSets = await this.ruleSetService.getAll().toPromise();
      this.configActions = await this.dictionaryService.getActions().toPromise();
      this.configActions = this.configActions["action"];
      this.lists = await this.dictionaryService.getLists().toPromise();      
      this.extractUsedLists();      
      this.nameValidators.push(new NotBlank());

    }catch(e){
      console.log(e)
      this.error = "An error has occurred. Try again ";
    }

  }

  loadListsFromDictionary(obj){

    this.lists = this.dictionaryService.extractListsFromDictionary(obj);    
    this.extractUsedLists();

  }

  onUpdateListElement($event){  
    this.update();
  }

  onAddListElement($event)
  {
    this.update();
  }

  onRemoveListElement($event, listElement:ListElement)
  {
    console.log("onRemoveListElement")
    
    if (confirm("Are you sure you want to delete this item?")) {
      listElement.values.splice($event["index"], 1);
      this.update();
    }

  }

  onErrorEditName(validator:Validator, listElement:ListElement){
    
    if(listElement.isNew && validator instanceof NotBlank)          
      this.appService.removeFromArray(this.lists, listElement);
    else
      this.updateError = validator.errorMessage;
      
  }


  saveField($event, listElement:ListElement){

    listElement.name = $event;
    this.update();

    {       
        this.updateError = "";
        /*skill.updateError = false;
                      
        let found = false;

        for(let i=0; i < this.skills.length; i++){
          if($event == this.skills[i].name){
            found = true;
            break;
          }
        }

        if(!found)
        {
          skill[name] = $event;        
          this.update();  
        }
        else
        {
          this.updateError = "Error. Cant update beacause field already exsits";
          //this.loadFields();
          skill.updateError = true;
        }*/                                                 
        
    } 
    
  }

  update(){
     
    this.dictionaryService.updateLists(this.lists).subscribe(data =>{
      
      for(let i=0; i < this.lists.length; i++){
        this.lists[i].isNew = false;
      }

    },(error)=>{      

      this.error = "An error has occurred. Try again ";
      
    })
    
  }
  loadActions(){

    this.error = "";

    this.dictionaryService.getActions().subscribe(data => {    
                           
      console.log("data action->" + JSON.stringify(data.action))
      this.configActions = data.action;      
    
    },(error)=>{      
      this.error = "An error has occurred. Try again ";
    });

  }

  addList(){

    let listElement:ListElement = new ListElement();
    listElement.isNew = true;
    this.lists.push(listElement)

  }

  loadRuleSet(){

    this.error = "";
    this.ruleSets = [];

    this.ruleSetService.getAll().subscribe((res) => { 
      
      this.ruleSets = res;      

		},(error)=>{      
			this.error = "An error has occurred. Try again ";
    });
  }

  existsListName(name){

    if(this.usedLists.indexOf(name) !== -1)          
      return true;    
    else     
      return false;    
  }

  extractUsedLists(){

    this.usedLists = [];

    for(let l=0; l < this.ruleSets.length; l++){
    
      let ruleSet = this.ruleSets[l];
      
      for(let i=0; i < ruleSet.ruleList.length; i++){

        for(let j=0; j < ruleSet.ruleList[i].actions.length; j++){        
          
          for(let k=0; k < ruleSet.ruleList[i].actions[j].fields.length; k++){
                        
            let configAction = this.getConfigFieldByName(ruleSet.ruleList[i].actions[j].name , ruleSet.ruleList[i].actions[j].fields[k].name)
            
            if(configAction["fieldType"] == "selectList")
            {              
              for(let m=0; m < this.lists.length; m++){

                if(configAction["name"] == this.lists[m].name && this.usedLists.indexOf(configAction["name"]) === -1)
                  this.usedLists.push(this.lists[m].name);
              }
            }
            
          }
          
        }
      }

    }
    
  }

  getConfigFieldByName(actionName:string, fieldName:string){
    
    for(let i=0; i < this.configActions.length; i++){

      for(let j=0; j < this.configActions[i]["fields"].length; j++){   
        if(this.configActions[i]["name"] == actionName && this.configActions[i]["fields"][j]["name"] == fieldName)
          return  this.configActions[i]["fields"][j];
      }
    }
    
    return false;

  }


  removeList(list:ListElement){

    if (confirm("Are you sure you want to delete this item?")) {
    
      this.appService.removeFromArray(this.lists, list)
      this.update();
    
    }    
    
  }
  
}
