import { Component, OnInit, Input } from '@angular/core';
import { RuleSet } from '@dashboard/models/rule-set'
import { ConditionNodesParent } from '@dashboard/models/condition-nodes-parent'
import { ConditionNode } from '@dashboard/models/condition-node'
import { Condition } from '@dashboard/models/condition';
import { isDefined } from '@ng-bootstrap/ng-bootstrap/util/util';
import { RuleSetService } from '@dashboard/services/rule-set.service'
import { Rule } from '@dashboard/models/rule';
import { ConditionField } from '@dashboard/models/condition-field'
import { ConfigRuleSetService} from '@dashboard/services/config-rule-set.service'
import { AppService } from '@core/services/app.service'

@Component({
  selector: 'conditions-set',
  templateUrl: './conditions-set.component.html',
  styleUrls: ['./conditions-set.component.scss']
})
export class ConditionsSetComponent implements OnInit {

  @Input() ruleSetType:string;
  @Input() rule:Rule;
  @Input() conditionNodesParent:ConditionNodesParent;
  error:string = "";
  errorGet:string = "";
  importedRuleList = [];  
  @Input() conditionFields:ConditionField[] = [];

  constructor(private ruleSetService:RuleSetService, private configRuleSetService:ConfigRuleSetService, private appService:AppService) { }

  ngOnInit(): void {    
    
  }

  isCondition(conditionNode:ConditionNode):boolean{
        
    if(conditionNode instanceof Condition)
      return true;    
    else          
      return false;
    
  }

  addSubCondition(){
    let conditionNodesParent:ConditionNodesParent = new ConditionNodesParent();
    let condition:Condition = new Condition();
    conditionNodesParent.conditionNodes.push(condition);
    this.conditionNodesParent.conditionNodes.push(conditionNodesParent)
  }

  addCondition(){
    let condition:Condition = new Condition();
    this.conditionNodesParent.conditionNodes.push(condition)
  }

  deleteSubCondition(conditionNodesParent){        
    this.ruleSetService.removeNode(this.rule, conditionNodesParent)
  }
  
  deleteCondition(condition:Condition){
    
    let unUsedParents:ConditionNodesParent[] = this.ruleSetService.getUnusedParents(this.rule, condition);

    if(unUsedParents.length > 0)    
      this.deleteSubCondition(unUsedParents[unUsedParents.length - 1]);    
    else
      this.appService.removeFromArray(this.conditionNodesParent.conditionNodes, condition)

  }

  changeConditionOperator(condition){
    
  }

}
