<div *ngIf="message" class="alert alert-info">{{message}}</div>
<div *ngIf="error" class="alert alert-danger">{{error}}</div>
<div *ngIf="updateError" class="alert alert-danger">{{updateError}}</div>

<h5 class="mt-3 mb-4">Actions</h5>

<div class="row">
  <div class="col">            
		<table class="table borderless">
			<thead class="thead-light">
				<tr>				
					<th>Name</th>
					<th>Label</th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let act of actions">
					<!-- act | json -->
					<td style="width:180px">
						<input-editable [params]="{value:act.name, width:'100%', disabled: existsActionName(act.name) || fieldsParams.disabled}" [iniFocus]="act.isNew"
						(onSave)="saveField($event, act, 'name')" [validators]="nameValidators" (onError)="onErrorEditName($event, act)"></input-editable>

					</td>
					<td style="width:180px">
						<input-editable [params]="{value:act.label, width:'100%'}" 
						(onSave)="saveField($event, act, 'label')" [validators]="nameValidators"></input-editable>

					</td>
					<td>
						
						<button-list [list]="act.fieldsNames" [params]="fieldsParams" 
						(remove)="onRemoveField($event, act)" (open)="openPopUp($event, act)"></button-list>
						<!--<button-list [list]="list.values" (new)="onAddListElement($event)" (update)="onUpdateListElement($event)" (remove)="onRemoveListElement($event)"></button-list>-->

					</td>
					<td style="width:40px;">
						<i class="fa fa-trash-o" [ngClass]="{'disabled': existsActionName(act.name) || this.usedActions.length == 0 || fieldsParams.disabled}" 
						(click)="!existsActionName(act.name) && removeAction(act)"></i>
					</td>
					
				</tr>
		</tbody>
	</table>                    
	<button (click)="addAction()" class="btn btn-primary" [disabled]="fieldsParams.disabled"> <i class="fa fa-plus"></i>  Add Action</button>

	</div>
</div>