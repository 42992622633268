import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '@dashboard/dashboard.component'
import { ClientAuthGuard } from '@core/guards/client-auth.guard'
import { TemplatesComponent } from '@dashboard/pages/templates/templates.component'
import { RulesDesignerComponent } from '@dashboard/pages/rules-designer/rules-designer.component'
import { ConfigurationComponent } from '@dashboard/pages/configuration/configuration.component'

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, 
    canActivate: [ClientAuthGuard],
    canActivateChild: [ClientAuthGuard],
    children: [
                {path:'templates', component: TemplatesComponent},
                {path:'rules-designer', component: RulesDesignerComponent},
                {path:'configuration', component: ConfigurationComponent},
                {path: '',  redirectTo: 'rules-designer', pathMatch: 'full'}
              ]
  },
  { path: '',  redirectTo: 'dashboard/rules-designer', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
