export class Template {
	templateId:string;
	templateName:string;
	description:string;
	template:string;
	isHtml:string;

	constructor() {
		this.templateId = "";
		this.templateName = "";
		this.description = "";
		this.template = "";
		this.isHtml = "true";
	}

}
