<div class="container">
    
  <!-- ruleEdit | json  -->
  <h5 class="mt-3 mb-4">When these conditions are met...</h5>

  <!-- ruleEdit.conditionNodesParent | json -->
  <conditions-set [conditionNodesParent]="ruleEdit.conditionNodesParent" [rule]="ruleEdit" [conditionFields]="conditionFields"></conditions-set>

  <div class="row">
    <div class="col py-2 pb-2">
        
      <h5 class="mt-3 mb-4">Do these actions....</h5>            
      <button class="btn btn-sm btn-secondary" (click)="addAction()"><i class="fa fa-plus"></i> Action</button>
    </div>
  </div>
  
  <div *ngFor="let action of ruleEdit.actions" class="row action-row">

    <div class="col-3 py-2 pb-2 pl-5">    

      <select class="form-control" [(ngModel)]="action.name" (change)="changeAction(action, $event)">    
          <option *ngFor="let act of configActions" [value]="act.name" [disabled]="isActionDisabled(action, act)">{{act.label}}</option>
      </select>

    </div>

    <div class="col-9 py-2 pb-2 pl-1">

      <div style="display: inline-block;" class="" *ngFor="let field of action.fields">
        
        <div class="mb-2 mr-2" *ngIf="getConfigFieldByName(action.name, field.name)['fieldType'] == 'text'">
          <div class="label-field"> {{getConfigFieldByName(action.name, field.name)['label']}} </div>
          <input style="width:300px" class="form-control ml-3 action-field-control" [(ngModel)]="field.value"/>
        </div>

        <div class="mb-2 mr-2" *ngIf="getConfigFieldByName(action.name, field.name)['optionsVar']">
          <div class="label-field">{{getConfigFieldByName(action.name, field.name)['label']}} </div>
          <select class="form-control ml-3 action-field-control" [(ngModel)]="field.value">
              <option *ngFor="let template of templates" [value]="template.templateName" >{{template.templateName}}</option>
          </select>
        </div>

        <div class="mb-2 mr-2" *ngIf="getConfigFieldByName(action.name, field.name)['fieldType'] == 'select'">

          <div class="label-field">{{getConfigFieldByName(action.name, field.name)['label']}} </div>
          <select class="form-control ml-3 action-field-control" [(ngModel)]="field.value" style="width:120px">
              <option *ngFor="let opt of getConfigFieldByName(action.name, field.name)['options']" [value]="opt.name" >{{opt.label}}</option>
          </select>
        </div>

        <div class="mb-2 mr-2" *ngIf="getConfigFieldByName(action.name, field.name)['fieldType'] == 'selectList'">
          
          <div class="label-field">{{getConfigFieldByName(action.name, field.name)['label']}} </div>
          
          <select class="form-control ml-3 action-field-control" [(ngModel)]="field.value" style="width:120px">
              <option *ngFor="let opt of getListValues(action.name, field.name)" [value]="opt" >{{opt}}</option>
          </select>
        </div>

      </div>

      <i title="Delete" class="fa fa-trash-o del-action-i" (click)="deleteAction(action)"></i>
    </div>


  </div>

  <div class="row my-4 mb-4">
      
      <div class="col border-top pt-4" style="justify-content: space-between;display: flex">
          <button type="button" class="btn btn-danger" (click)="deleteRule()">Delete</button>
          <span>&nbsp;</span>
          <div>
              <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">Close</button> 			
              <button type="submit" class="btn btn-primary"  (click)="save()">Save</button>
          </div>
      </div>
  </div>

</div>