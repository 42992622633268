import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges  } from '@angular/core'
import { ConfigAction } from '@dashboard/models/config-action'
import { ListElement } from '@dashboard/models/list-element'
import { DictionaryService } from '@dashboard/services/dictionary.service'
import { TemplateService } from '@dashboard/services/template.service'
import { Template } from '@dashboard/models/template'
import { AppService } from '@core/services/app.service'
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'


@Component({
  selector: 'setup-field',
  templateUrl: './setup-field.component.html',
  styleUrls: ['./setup-field.component.scss']
})
export class SetupFieldComponent implements OnInit,OnChanges  {

  @Input() field:any = {};
  @Input() fieldIndex:any;
  @Input() actions: ConfigAction[] = []
  @Input() configAction:ConfigAction;

  lists:ListElement[] = []
  fieldEdit:any;
  types:any[] = [{name:"text",label:"Text"}, {name:"selectVar",label:"Select Template"}, {name:"select", label:"Select Option"}, {name:"selectList",label:"Select List"}];
  error:string;
  selectedList:string;
  templates:Template[] = [];
  options:any[] = [];
  optionName:string;
  optionLabel:string;
  selectedOption:any = {};
  new:boolean = false;

  @Output() save = new EventEmitter<any>();

  constructor(private dictionaryService:DictionaryService, private appService:AppService, private modalService: NgbModal) {

  }

  ngOnChanges(changes: SimpleChanges) {
    
    console.log("ngOnChanges")
    console.log(changes)

  }
  
  async ngOnInit(){    

    console.log("ON INIT")
    console.log("init field")
    console.log(this.field)

    if(Object.keys(this.field).length)
    {
      this.fieldEdit = JSON.parse(JSON.stringify(this.field));
      this.fieldEdit.fieldType = this.field.fieldType;

      if(this.fieldEdit.fieldType == "select")
        this.options = JSON.parse(JSON.stringify(this.fieldEdit.options));
        
    }
    else{
      this.fieldEdit = {};
      this.fieldEdit.fieldType = this.types[0].name;      
      this.new = true;
    }    
      
    this.changeType();
    this.error = "";
      
    try{      
      this.lists = await this.dictionaryService.getLists().toPromise();
    }catch(e){
      console.log(e)
      this.error = "An error has occurred. Try again ";
    }
    
  }

  clearOptions(){

    this.selectedOption = {};
    this.optionName = "";
    this.optionLabel = "";

  }

  selectOption(opt){

    if(this.selectedOption!=opt)
    {
      this.selectedOption = opt;
      this.optionName = opt.name;
      this.optionLabel = opt.label;
    }
    else
      this.clearOptions();

  }

  cancelOption(){

    this.clearOptions();

  }

  saveOption(){
    
    if(Object.keys(this.selectedOption).length){
      this.selectedOption.name = this.optionName;
      this.selectedOption.label = this.optionLabel;      
    }
    else
      this.options.push({name:this.optionName, label:this.optionLabel})

    this.clearOptions();

  }
  

  changeType(){
    
    console.log("selectType->" + this.fieldEdit.fieldType)
    if(this.fieldEdit.fieldType!="select")
      delete this.fieldEdit["options"];
    else if(!this.fieldEdit["options"])
      this.fieldEdit["options"] = this.options;

    if(this.fieldEdit.fieldType!="selectList")
      delete this.fieldEdit["optionsList"];

    if(this.fieldEdit.fieldType!="selectVar")
      delete this.fieldEdit["optionsVar"];
    else
      this.fieldEdit["optionsVar"] = "templateList";

  }
  

  removeOption(opt){
    
    this.appService.removeFromArray(this.options, opt);

  }

  update(){    
  
    if(this.fieldEdit.fieldType=="select")
      this.fieldEdit.options = this.options;   

    if(!this.new)
    {
      this.appService.deepCopy(this.fieldEdit, this.field)    
      this.configAction.fieldsNames[this.fieldIndex] = this.field.label;
    }
    else
    {
      this.configAction.fields.push(this.fieldEdit)
      this.configAction.fieldsNames.push(this.fieldEdit.label)
    }

    //console.log("configAction before UPDATE")
    //console.log(this.configAction)
    this.save.emit({field: this.field, index:this.fieldIndex})
    this.modalService.dismissAll();
      
  }

  cancel(){
    console.log(JSON.stringify(this.fieldEdit))
    console.log(JSON.stringify(this.field))

    if(JSON.stringify(this.fieldEdit) !== JSON.stringify(this.field))
    {
      if (confirm("Are you sure you want to leave without saving"))
        this.modalService.dismissAll();
      
    }
    else
      this.modalService.dismissAll();
        
  }  
  

}