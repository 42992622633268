import { Action } from "./action";
import { Condition } from "./condition";
import { ConditionNode } from "./condition-node";
import { ConditionNodesParent } from './condition-nodes-parent'

export class Rule implements ConditionNode{
    
  name:string="";
  stopEvalWhenMatched:boolean = false;
  description:string = "";
  conditions:Condition[] = [];
  actions:Action[] = [];
  conditionType:string = "";
  actionOrder:string = "";
  pendingToSave:boolean = false;  
  conditionNodesParent:ConditionNodesParent;
  moved:boolean = false;
  isNew:boolean = false;
  
  constructor(){
    
    this.conditionNodesParent = new ConditionNodesParent();
    this.conditionNodesParent.isRoot = true;
    
  }

}

