import { Rule } from "./rule";

export class RuleSet {

  id:any = false;
  ruleSetName:string = "";
  ruleOrder:string = "";
  ruleList:Rule[] = [];
  email:boolean = true;
  chat:boolean = true;
  isNew:boolean = false;

  constructor(){
      
  }
}
