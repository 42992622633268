import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppService } from '@core/services/app.service'

@Injectable({
  providedIn: 'root'
})
export class ConfigRuleSetService {

  apiSegment:string = "fields";

  constructor(private http:HttpClient, private appService:AppService) { }

  //update(configFields:ConfigConditionsFields[]): Observable<any> {
  update(configFields:any[]): Observable<any> {
		return this.http.put(this.appService.getApiBaseUrl() + this.apiSegment, {fields: configFields});
  }

  get(): Observable<any> {
		return this.http.get(this.appService.getApiBaseUrl() + this.apiSegment);
  }
}
