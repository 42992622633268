<div class="name-cell-component" [ngClass]="{'disabled':params.disabled}" [ngStyle]="{'height': params.height + 'px', 'width': params.width + 'px'}">
	<div class="name-cell-text">
		<i *ngIf="!editing" class="edit-icon fa fa-pencil" (click)="!params.disabled && edit()"></i>
		<div class="ds-editable-component">
			<div *ngIf="!editing" class="ds-text-component" dir="auto">
				<span style="padding-left: 5px; padding-right: 5px;">{{ params.value }}</span>
			</div>			
			<input id="{{inputId}}" *ngIf="editing" class="ds-editable-input"  #inputValue name="val" [(ngModel)]="params.value" 
			 (keypress)="keyPressNumbers($event)" autocomplete="off" (blur)="onBlur()"/>
		</div>
	</div>
</div>

