import { Validator } from '@shared/interfaces/validator'

export class NotBlank implements Validator{
    
  errorMessage:string = "Error. field name can't be empty";
    
  constructor(msg = null){
    
    if(msg) this.errorMessage = msg;

  }

  validate(val:any){

    if(val)
      return true;
    else
      return false;

  }
}
