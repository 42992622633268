export class ConfigAction {

  name:string = "";
  label:string = "";
  fields:any[] = [];
  fieldsNames:any[] = [];

  isNew:boolean = false;

}


