<div *ngIf="message" class="alert alert-info">{{message}}</div>
<div *ngIf="error" class="alert alert-danger">{{error}}</div>
<div *ngIf="updateError" class="alert alert-danger">{{updateError}}</div>

<h5 class="mt-3 mb-4">Lists</h5>

<div class="row">
  <div class="col">            

		<table class="table borderless">
			<thead class="thead-light">
				<tr>				
					<th>Name</th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let list of lists">
					<td style="width:200px">
						<input-editable [params]="{value:list.name, width:'100%', disabled: existsListName(list.name) || disabledAll}" [iniFocus]="list.isNew"
						(onSave)="saveField($event, list)" [validators]="nameValidators" (onError)="onErrorEditName($event, list)"></input-editable>
					</td>
					<td>

						<button-list [params]="skillsParams" [list]="list.values" (new)="onAddListElement($event)" 
						(update)="onUpdateListElement($event)" (remove)="onRemoveListElement($event, list)"></button-list>

					</td>

					<td style="width:40px;">
						<i class="fa fa-trash-o" [ngClass]="{'disabled': existsListName(list.name) || this.usedLists.length == 0 || disabledAll}" 
						(click)="!existsListName(list.name) && removeList(list)"></i>
					</td>
					
				</tr>
		</tbody>
	</table>                    
	<button (click)="addList()" class="btn btn-primary" [disabled]="disabledAll"> <i class="fa fa-plus"></i>  Add List</button>

	</div>
</div>
<!--<div class="row mt-3 mb-3">
	<div class="input-group col-md-2">
		<button [disabled]="!changed" (click)="saveActions()" class="btn btn-primary"> Save</button>
		
	</div>
		
</div>-->