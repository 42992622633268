<div *ngIf="message" class="alert alert-info">{{message}}</div>
<div *ngIf="error" class="alert alert-danger">{{error}}</div>
<div *ngIf="updateError" class="alert alert-danger">{{updateError}}</div>

<h5 class="mt-4 mb-4">Fields</h5>
  <div class="row">
		<div class="col">
      
			<table class="table borderless">
				<thead class="thead-light">
					<tr>				
						<th style="width:200px">Name</th>
						<th>Label</th>
						<th>Email</th>
						<th>Chat</th>
						<th></th>
					</tr>
				</thead>
				<tbody>          
					<tr *ngFor="let field of fields">
						<td [ngClass]="{'error':field.updateError}">              
							<input-editable [params]="{value:field.name, width:'100%', disabled: existsConditionName(field.name) || disabled}" [iniFocus]="field.isNew"
							(onSave)="saveField($event, field, 'name')" [validators]="nameValidators" (onError)="onErrorEditName($event, field)"></input-editable>              
						</td>
						<td>
							<input-editable [params]="{value:field.label, width:'100%', disabled: disabled}" [validators]="labelValidators"
							(onSave)="saveField($event, field, 'label')" (onError)="onErrorEditLabel($event, field)"></input-editable>
						</td>
						<td style="width:40px; text-align: center;">
              <input name="enabledForEmail" type="checkbox" [(ngModel)]="field.email" (change)="update()" [disabled]="disabled"/>
            </td>
						<td style="width:40px; text-align: center;">
              <input name="enabledForChat" type="checkbox" [(ngModel)]="field.chat" (change)="update()" [disabled]="disabled"/>
            </td>

            <td style="width:40px; text-align: center;">
              <i class="fa fa-trash-o" [ngClass]="{'disabled': existsConditionName(field.name) || disabled}" (click)="!disabled && !existsConditionName(field.name) && remove(field)"></i>
            </td>
					</tr>
			</tbody>
		</table>                    
		<button (click)="addField()" class="btn btn-primary" [disabled]="disabled"> <i class="fa fa-plus"></i>  Add Field</button>

	</div>
</div>