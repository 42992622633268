
<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-6">        
    <ruleset></ruleset>

    <div class="mt-5 mb-2">      
      <!-- Solid divider -->
      <hr class="solid">
    </div>

  </div>
  <div class="col-md-3"></div>
</div>

<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-6">

    <h2 class="mt-3">Dictionary</h2>
    <div class="mb-2" style="justify-content: flex-end; display: flex;">

      <div class="form-control" style="width: auto;border: 0;">{{fileName}}</div>
      <div class="custom-file" id="customFile" style="max-width:220px">
        <input #fileInpu type="file" class="custom-file-input" id="exampleInputFile" (change)="import($event)" (click)="fileInpu.value=null">
        <label class="custom-file-label" for="exampleInputFile">
          Import 
        </label>
      </div>
      <button class="btn btn-secondary ml-2" title="Export Ruleset" (click)="export()">Export</button><i class="fas fa-file-export"></i>
	
    </div>
    <div *ngIf="pendingToImport" class="mt-3 mb-3">
      
      <div style="padding: 10px;background: #efe;color: green;border: 1px solid #86B245;">
        Import pending to save. Are you want save the changes? 
        <button class="btn btn-primary ml-2" (click)="savePendingImport()">Save</button>
        <button class="btn btn-secondary ml-2" (click)="discardPendingImport()">Discard</button>
      </div>
      
    </div>
    <conditions-fields #conditionFieldsCom></conditions-fields>        

  </div>
  <div class="col-md-3"></div>
</div>

<div class="row mt-4">
  <div class="col-md-3"></div>
  <div class="col-md-6">        
    <actions #actionsCom ></actions>
  </div>
  <div class="col-md-3"></div>
</div>

<div class="row mt-4">
  <div class="col-md-3"></div>
  <div class="col-md-6">        
    <lists #listsCom ></lists>
  </div>
  <div class="col-md-3"></div>
</div>

<!--<div class="row mt-4">
  <div class="col-md-3"></div>
  <div class="col-md-6">
    <dictionary #dictionaryCom></dictionary>
  </div>
  <div class="col-md-3"></div>
</div>-->
