<div class="container">
    
  <div class="row">
    <div class="col py-2 pb-2">
      <h4 class="mt-2 mb-2">Field setup</h4>
      
      <form spellcheck="false">
        <div class="form-group">
          <label for="formGroupExampleInput">Name</label>
          <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Name"  name="name" [(ngModel)]="fieldEdit.name" autocomplete="off">
        </div>
        <div class="form-group">
          <label for="formGroupExampleInput2">Label</label>
          <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Label" name="label" [(ngModel)]="fieldEdit.label" autocomplete="off">
        </div>
        
        <div class="form-group">
          <label for="formGroupExampleInput2">Type</label>
          <select class="form-control form-controlg" [(ngModel)]="fieldEdit.fieldType" name="fieldType" (change)="changeType()">            
            <option [value]="type.name" *ngFor="let type of types">{{type.label}}</option>
          </select>
        </div>

        <div *ngIf="fieldEdit.fieldType == 'selectList'" class="form-group">
          <label for="formGroupExampleInput2">Lists</label>
          <select class="form-control form-controlg" [(ngModel)]="fieldEdit.optionsList" name="selectedList">            
            <option [value]="list.name" *ngFor="let list of lists">{{list.name}}</option>
          </select>
        </div>
        
        <div *ngIf="fieldEdit.fieldType == 'select'">
          <h6>Options</h6>
          <div class="row  py-2 pb-2">
            <div class="col-md-4">
              <input type="text" class="form-control" placeholder="Name" name="optionName" [(ngModel)]="optionName" autocomplete="off">
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" placeholder="Label" name="optionLabel" [(ngModel)]="optionLabel" autocomplete="off">
            </div>
            <div class="col-md-4">
              
              <!--<button class="btn btn-primary"><i class="fa fa-floppy-o"></i></button>-->
              <input type="button" class="btn btn-primary" value="Save" (click)="saveOption()" [disabled]="!optionName || !optionLabel">
              <button class="btn btn-secondary ml-2" (click)="cancelOption()"><i class="fa fa-times"></i></button>
            </div>
          </div>
                    
        </div>
        <table *ngIf="fieldEdit.fieldType == 'select'" class="table borderless">
          <thead class="thead-light">
            <tr>				
              <th>Name</th>
              <th>Label</th>
              <th></th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let opt of options" class="selectable" (click)="selectOption(opt)" [ngClass]="{'select': selectedOption == opt}">
              <td>                
                {{ opt.name }}    
              </td>
              <td>                
                {{ opt.label }}    
              </td>
              <td style="width:40px;">
                <i class="fa fa-trash-o" (click)="removeOption(opt)"></i>
              </td>
              
            </tr>
          </tbody>
        </table>      
        
      </form>
            
    </div>
  </div>

  <div class="row my-4 mb-4">
      
    <div class="col border-top pt-4" style="justify-content: space-between;display: flex">
        <!--<button type="button" class="btn btn-danger" (click)="deleteRule()">Delete</button>
        <span>&nbsp;</span>-->
        <div></div>
        <div>
            <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">Close</button> 			
            <button type="submit" class="btn btn-primary"  (click)="update()" [disabled]="!fieldEdit.name || !fieldEdit.label">Save</button>
        </div>
    </div>
</div>

  <!--
  <div *ngFor="let action of ruleEdit.actions" class="row action-row">

    <div class="col-3 py-2 pb-2 pl-5">    

      <select class="form-control" [(ngModel)]="action.name" (change)="changeAction(action, $event)">    
          <option *ngFor="let act of configActions" [value]="act.name" [disabled]="isActionDisabled(action, act)">{{act.label}}</option>
      </select>

    </div>

    <div class="col-9 py-2 pb-2 pl-1">

      <div style="display: inline-block;" class="" *ngFor="let field of action.fields">
        
        <div class="mb-2 mr-2" *ngIf="getConfigFieldByName(action.name, field.name)['fieldType'] == 'text'">
          <div class="label-field"> {{getConfigFieldByName(action.name, field.name)['label']}} </div>
          <input style="width:300px" class="form-control ml-3 action-field-control" [(ngModel)]="field.value"/>
        </div>

        <div class="mb-2 mr-2" *ngIf="getConfigFieldByName(action.name, field.name)['optionsVar']">
          <div class="label-field">{{getConfigFieldByName(action.name, field.name)['label']}} </div>
          <select class="form-control ml-3 action-field-control" [(ngModel)]="field.value">
              <option *ngFor="let template of templates" [value]="template.templateName" >{{template.templateName}}</option>
          </select>
        </div>

        <div class="mb-2 mr-2" *ngIf="getConfigFieldByName(action.name, field.name)['fieldType'] == 'select'">

          <div class="label-field">{{getConfigFieldByName(action.name, field.name)['label']}} </div>
          <select class="form-control ml-3 action-field-control" [(ngModel)]="field.value" style="width:120px">
              <option *ngFor="let opt of getConfigFieldByName(action.name, field.name)['options']" [value]="opt.name" >{{opt.label}}</option>
          </select>
        </div>

        <div class="mb-2 mr-2" *ngIf="getConfigFieldByName(action.name, field.name)['fieldType'] == 'selectList'">
          
          <div class="label-field">{{getConfigFieldByName(action.name, field.name)['label']}} </div>
          
          <select class="form-control ml-3 action-field-control" [(ngModel)]="field.value" style="width:120px">
              <option *ngFor="let opt of getListValues(action.name, field.name)" [value]="opt" >{{opt}}</option>
          </select>
        </div>

      </div>

      <i title="Delete" class="fa fa-trash-o del-action-i" (click)="deleteAction(action)"></i>
    </div>


  </div>
  -->

</div>