import { Component, OnInit, Input } from '@angular/core'
import { Rule } from '@dashboard/models/rule'
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { TemplateService } from '@dashboard/services/template.service'
import { RuleSet } from '@dashboard/models/rule-set'
import { Condition } from '@dashboard/models/condition'
import { Template } from '@dashboard/models/template'
import { Action } from '@dashboard/models/action'
import { ActionField } from '@dashboard/models/action-field'
import { Skill } from '@dashboard/models/skill'
import { RuleSetService } from '@dashboard/services/rule-set.service'
import { ConfigRuleSetService} from '@dashboard/services/config-rule-set.service'
import { ConditionField } from '@dashboard/models/condition-field'
import { DictionaryService } from '@dashboard/services/dictionary.service'
import { ListElement } from '@dashboard/models/list-element'

@Component({
  selector: 'app-setup-rule',
  templateUrl: './setup-rule.component.html',
  styleUrls: ['./setup-rule.component.scss']
})
export class SetupRuleComponent implements OnInit {

  error:string = "";  
  modalTitle:string = "";
  conditionType:string = "";
  @Input() rule:Rule;
  @Input() ruleSet:RuleSet;
  templates:Template[] = [];
  errorGet:string = "";
  //skills:Skill[] = [];
  lists:ListElement[] = [];
  ruleEdit:Rule;
  ruleSetType:string;
  conditionFields:ConditionField[] = [];
  configActions:any[] = [];
  
  constructor(private modalService: NgbModal, private templateService:TemplateService, private configRuleSetService:ConfigRuleSetService,
    private ruleSetService:RuleSetService, private dictionaryService:DictionaryService) { }

  async ngOnInit(){
  
    console.log("setupRule rule->")
    console.log(this.rule)

    this.ruleEdit = new Rule();    
    this.ruleSetService.cloneRule(this.rule, this.ruleEdit);
    this.conditionType = this.ruleEdit.conditionType;

    this.loadTemplates();
    this.loadLists();
    this.loadActions();
    this.loadConditions();    

    if(this.ruleSet.ruleSetName == "EmailRuleSet")
      this.ruleSetType = "email";
    else if(this.ruleSet.ruleSetName == "ChatRuleSet")      
      this.ruleSetType = "chat";

  }

  loadConditions(){

    this.error = "";

    this.configRuleSetService.get().subscribe((res) => {

      for(let i=0; i < res.fields.length; i++){

        if((this.ruleSet.email === true && res.fields[i].email === true) || (this.ruleSet.chat === true && res.fields[i].chat === true))
        {
          let field = new ConditionField();

          field.name = res.fields[i].name;
          field.label = res.fields[i].label;
          field.email = res.fields[i].email;
          field.chat = res.fields[i].chat;
          
          this.conditionFields.push(field);
        }
      }

    },(error)=>{
      this.error = "An error has occurred. Try again ";
    });

  }
  loadLists(){

    this.error = "";

    this.dictionaryService.getLists().subscribe(data => {    
      
      this.lists = data;
      
    },(error)=>{      
      this.error = "An error has occurred. Try again ";
    });

  }

  loadTemplates(){

    this.errorGet = "";
    this.templateService.getAll().subscribe((res) => { 
			
      this.templates = res;    
   
		},(error)=>{
			this.errorGet = "An error has occurred. Try again ";
    });

  }

  loadActions(){

    this.error = "";

    this.dictionaryService.getActions().subscribe(data => {    
                                 
      this.configActions = data.action;      
    
    },(error)=>{      
      this.error = "An error has occurred. Try again ";
    });

  }

  isActionDisabled(action, act){

    for(let i=0; i < this.ruleEdit.actions.length; i++){
      if(act.name == this.ruleEdit.actions[i].name && act.name!==action.name)
        return true;
    }
    return false;
  }

  deleteAction(action:Action){

    for(let i=0; i < this.ruleEdit.actions.length; i++){
      if(action == this.ruleEdit.actions[i])
      {
        this.ruleEdit.actions.splice(i, 1);
        let arrOrder = this.ruleEdit.actionOrder.split(",");
        for(let j=0; j < arrOrder.length; j++){
          if(arrOrder[j] == action.name)
            arrOrder.splice(j, 1);
        }

        this.ruleEdit.actionOrder = arrOrder.join(",");
        
      }
    }

  }

  getListValues(actionName, fieldName){
    
    let listName = this.getConfigFieldByName(actionName, fieldName)['optionsList']
    let list:any[] = [];    

    for(let i=0; i < this.lists.length; i++)
    {
      if(this.lists[i].name == listName)
      {
        for(let j=0; j < this.lists[i].values.length;j++){
          list.push(this.lists[i].values[j])
        }

        break;
      }

    }

    return list;
      

  }
  getConfigFieldByName(actionName:string, fieldName:string){

    for(let i=0; i < this.configActions.length; i++){
      for(let j=0; j < this.configActions[i]["fields"].length; j++){
        if(this.configActions[i]["name"] == actionName && this.configActions[i]["fields"][j]["name"] == fieldName)
          return  this.configActions[i]["fields"][j];
      }
    }
    
    return false;

  }

  save(){    

    this.ruleEdit.pendingToSave = false;

    this.ruleSetService.cloneRule(this.ruleEdit, this.rule);

    this.ruleSetService.update(this.ruleSet).subscribe((res) => {       
      this.modalService.dismissAll();  
		},(error)=>{
      
			this.error = "An error has occurred. Try again ";
    });
    
  }

  cancel(){
    this.modalService.dismissAll();
  }

  changeConditionOperator(condition:Condition){
    
    //if(condition.operator == "present" || condition.operator == "blank")
      //condition.value = "";

  }

  addCondition(){
    let condition:Condition = new Condition();
    this.ruleEdit.conditions.push(condition)
  }

  addAction(){
    let action:Action = new Action();
    action.name = "markAutoReply";
    this.ruleEdit.actions.push(action);

    this.changeAction(action);
    
    if(this.ruleEdit.actionOrder == "")
      this.ruleEdit.actionOrder = action.name;
    else 
      this.ruleEdit.actionOrder = this.ruleEdit.actionOrder + "," + action.name;

  }

  updateActionOrder(action:Action, first = true){

  }

  changeAction(action:Action, event:any = false){

    action.fields = [];

    let act = {};
    for(let i=0; i< this.configActions.length; i++){
      if(this.configActions[i]["name"] == action.name)
      {
        act = this.configActions[i];

        for(let j=0; j < act["fields"].length; j++){
          let field = act["fields"][j];
          let configField = this.getConfigFieldByName(action.name, field.name);

          let actionField1 = new ActionField();
          actionField1.name = configField["name"];
          actionField1.value = "";
          action.fields.push(actionField1);  

        }

      }
    }

    
    let arrOrder = [];

    for(let i=0; i < this.ruleEdit.actions.length; i++)
      arrOrder.push(this.ruleEdit.actions[i].name)
    
    this.ruleEdit.actionOrder = arrOrder.join(",");
  }

  deleteRule(){
    
    if(confirm("Are you sure?")){
      
      this.error = "";
      let ruleNames:string[] = [];      

      for(let i=0; i < this.ruleSet.ruleList.length; i++){
        if(this.ruleSet.ruleList[i].name == this.ruleEdit.name)
        {
          ruleNames.push(this.ruleEdit.name)
          this.ruleSet.ruleList.splice(i, 1);
        }
      }

      let arrOrder = this.ruleSet.ruleOrder.split(",");

      for(let i=0; i < arrOrder.length; i++){
        for(let j=0; j < ruleNames.length; j++){
          if(arrOrder[i] == ruleNames[j])
            arrOrder.splice(i, 1)
        }
      }

      this.ruleSet.ruleOrder = arrOrder.join(",");
      
      this.ruleSetService.update(this.ruleSet).subscribe((res) => {       
        this.modalService.dismissAll();  
      },(error)=>{
        
        this.error = "An error has occurred. Try again ";
      });
    }

  }

}
 