import { Component, OnInit, Input } from '@angular/core'
import { DictionaryService } from '@dashboard/services/dictionary.service'
import { Validator } from '@shared/interfaces/validator'
import { NotBlank } from '@shared/classes/not-blank'
import { AppService } from '@core/services/app.service'
import { RuleSetService } from '@dashboard/services/rule-set.service'
import { RuleSet } from '@dashboard/models/rule-set'
import { ConfigAction } from '@dashboard/models/config-action'
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { SetupFieldComponent } from '@dashboard/components/configuration/actions/setup-field/setup-field.component'

@Component({
  selector: 'actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  changed:boolean = false;
  updateError:string = "";
  error:string = "";
  message:string = "";
  actions: ConfigAction[] = []
  ruleSets:RuleSet[] = [];
  nameValidators:Validator[] = [];
  usedActions:any[] = [];
  fieldsParams:any = {popup:false, removeItem:false}

  constructor(private dictionaryService:DictionaryService, private appService:AppService, private ruleSetService:RuleSetService, private modalService: NgbModal) { }

  ngOnInit(): void {
    
    this.loadActions();

  }

  disabled(){
    
    this.fieldsParams.disabled = true;

  }

  enabled(){

    this.fieldsParams.disabled = false;

  }

  async loadActions(){
    
    this.enabled();
    this.error = "";  

    try{

      this.ruleSets = await this.ruleSetService.getAll().toPromise();      
      this.actions = await this.dictionaryService.getActionsNEW().toPromise();                  
      //console.log(this.actions)
      this.extractUsedActions();
      
      this.nameValidators.push(new NotBlank());
    }catch(e){
      console.log(e)
      this.error = "An error has occurred. Try again ";
    }

  }

  loadActionsFromDictionary(obj){

    this.actions = this.dictionaryService.extractActionsFromDictionary(obj);
    this.extractUsedActions();

  }

  extractUsedActions(){
        
    this.usedActions = [];
    let actionNames = this.actions.map((a) => a.name)

    for(let l=0; l < this.ruleSets.length; l++){

      let ruleSet = this.ruleSets[l];

      for(let i=0; i < ruleSet.ruleList.length; i++){

        for(let j=0; j < ruleSet.ruleList[i].actions.length; j++){        

          let action = ruleSet.ruleList[i].actions[j];          

          if(actionNames.indexOf(action["name"]) >= 0)
            this.usedActions.push(action["name"]);

        }
      }
    }

  }

  onRemoveField($event, configAction:ConfigAction){

    if (confirm("Are you sure you want to delete this item?")) {
      configAction.fieldsNames.splice($event["index"], 1);        
      configAction.fields.splice($event["index"], 1);
      this.update()
      console.log("On remove field")
      console.log($event)
    } 
    
  }

  existsActionName(name){

    if(this.usedActions.indexOf(name) !== -1)          
      return true;    
    else     
      return false;    
  }

  onErrorEditName(validator:Validator, action:ConfigAction){
    
    if(action.isNew && validator instanceof NotBlank)          
      this.appService.removeFromArray(this.actions, action);
    else
      this.updateError = validator.errorMessage;
      
  }


  saveField($event, action, type){

    if(type == 'name')
      action.name = $event;
    else if(type == 'label')
      action.label = $event;

    this.update();

  }

  update(callback = ()=>{}){

    this.error = "";
            
    this.dictionaryService.updateActionsNEW(this.actions).subscribe((res) => {
      
      this.changed = false;
      callback();

    },(error)=>{
      
      this.error = "An error has occurred. Try again ";
    });

  }

  addAction(){

    let configAction:ConfigAction = new ConfigAction();
    configAction.isNew = true;
    this.actions.push(configAction)

  }

  openPopUp(event, configAction:ConfigAction){

    console.log("open popup")    
    console.log(event)
        
    const modalRef = this.modalService.open(SetupFieldComponent,  { windowClass : ""});
    modalRef.componentInstance.configAction = configAction;
    
    if(!event["new"])
    {
      modalRef.componentInstance.field = configAction.fields[event["index"]];
      modalRef.componentInstance.fieldIndex = event["index"];    
    }    

    let self = this;

    modalRef.componentInstance.save.subscribe(data => {
      self.update();
    });

  }

  removeAction(action){

    if (confirm("Are you sure you want to delete this item?")) {
      
      console.log("On remove action")
      this.appService.removeFromArray(this.actions, action);
      this.update();
    
    } 

  }

}
