import { Component, OnInit, ViewChild } from '@angular/core'
import { AppService } from '@core/services/app.service'
import { DictionaryService } from '@dashboard/services/dictionary.service'
import { ConditionsFieldsComponent } from '@dashboard/components/configuration/conditions-fields/conditions-fields.component'
import { DictionaryComponent } from '@dashboard/components/configuration/dictionary/dictionary.component'
import { ListsComponent } from '@dashboard/components/configuration/lists/lists.component'
import { ActionsComponent } from '@dashboard/components/configuration/actions/actions.component'

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  fileName:string = "";
  error:string = "";
  dictionary:any;
  pendingToImport:boolean = false;
  
  @ViewChild("conditionFieldsCom") conditionFieldsCom: ConditionsFieldsComponent;
  //@ViewChild("dictionaryCom") dictionaryCom: DictionaryComponent;  
  @ViewChild("listsCom") listsCom: ListsComponent;  
  @ViewChild("actionsCom") actionsCom: ActionsComponent;  
  

  constructor(private appService:AppService, private dictionaryService:DictionaryService) { }

  ngOnInit(): void {
  }

  import(evt): void {

    this.fileName = evt.target.files[0].name;
    console.log(this.fileName)
        
    let file = evt.target.files[0];

    let fileReader: FileReader = new FileReader();
    let self = this;

    
    fileReader.onloadend = function(x) {      
      let res:string = fileReader.result as string;
      try{

        let obj: any = JSON.parse(res);
        self.dictionary = obj;
        //self.editor.set(self.dictionary);
        self.conditionFieldsCom.loadFieldsFromDictionary(obj);        
        self.listsCom.loadListsFromDictionary(obj);
        self.actionsCom.loadActionsFromDictionary(obj);
        self.activatePendingImport();      

      }catch(e){console.log(e); self.error = "Incorrect File json format"}
    }
    fileReader.readAsText(file);
  }

  activatePendingImport(){
    
    this.pendingToImport = true;
    this.actionsCom.disabled();
    this.conditionFieldsCom.disabled = true;
    this.listsCom.disabled();

  }
  async export(){

    try{
      let dictionary = await this.dictionaryService.get().toPromise();

      let text:string = JSON.stringify(dictionary, null, 2);
      let filename:string = "dictionary.json";
      this.appService.saveFile(text, filename);
    }catch(e){
      this.error = "An error has occurred. Try again ";
    }

  }  

  discardPendingImport(){
    
    this.dictionary = {};
    this.conditionFieldsCom.loadFields();
    //this.dictionaryCom.loadDictionary();
    this.listsCom.loadLists();
    this.actionsCom.loadActions();
    this.fileName  = "";
    this.pendingToImport = false;

  }

  async savePendingImport(){
    
    try{    

      await this.dictionaryService.update(this.dictionary).toPromise();
      this.dictionary = {};    
      this.fileName  = "";
      this.pendingToImport = false;
      //this.listsCom.loadLists();
      this.listsCom.enabled()
      this.actionsCom.enabled();
      this.conditionFieldsCom.disabled = false;

    }catch(e){
      this.error = "An error has occurred. Try again ";
    }
  }

}
