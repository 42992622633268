<form name="agentForm" class="" #form="ngForm"  novalidate>
    
	<div *ngIf="error" class="alert alert-danger" role="alert">
			{{error}}
	</div>
	<div class="modal-header">            
		<h5 class="modal-title" id="saveModalLabel">{{modalTitle}}</h5>
		<button type="button" class="close" (click)="close()" aria-label="Close">
		<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		
			<div class="form-group" *ngIf="createMode">
				<label for="tName" class="col-form-label">Name:</label>
				<input type="text" class="form-control" id="name"  #name="ngModel" name="name" [(ngModel)]="selTemplate.templateName" autocomplete="off" required>

				<div *ngIf="name.invalid && (name.dirty || name.touched)" class="field-error">
					<div *ngIf="name.errors.required">
						Field is required.
					</div>
				</div>
			</div>   		
			<div class="form-group" *ngIf="createMode">
				<label for="tName" class="col-form-label">Description:</label>
				<input type="text" class="form-control" id="description"  #description="ngModel" name="description" [(ngModel)]="selTemplate.description" 
				autocomplete="off" required>

				<div *ngIf="description.invalid && (description.dirty || description.touched)" class="field-error">
					<div *ngIf="description.errors.required">
						Field is required.
					</div>
				</div>
			</div>
			<!--<div class="form-group">
				<label for="tName" class="col-form-label">Template:</label>
				<textarea id="template" class="form-control" #template="ngModel" name="template" rows="8"
				[(ngModel)]="selTemplate.template" required>
				
				</textarea>
				<div *ngIf="template.invalid && (template.dirty || template.touched)" class="field-error">
					<div *ngIf="template.errors.required">
						Field is required.
					</div>
				</div>
			</div>-->

			<div class="form-group">
				<label for="tName" class="col-form-label">Template:</label>
				<div class="NgxEditor__Wrapper">
					<ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
					<ngx-editor name="template" (ngModelChange)="onChangeEditor($event)" 
						[editor]="editor"
						[ngModel]="htmlTxt"
						[disabled]="false"
						[placeholder]="'Type here...'"
					></ngx-editor>
				</div>
			</div>			

			<div class="custom-file" id="customFile">
				<input type="file" class="custom-file-input" id="exampleInputFile" (change)="onChange($event)">
				<label class="custom-file-label" for="exampleInputFile">
					Import template
				</label>
			</div>
	</div>
	
	<div class="modal-footer">          
		
		<button *ngIf="!createMode" style="float: right;" type="button" class="btn btn-danger" (click)="delete()">Delete</button>
		<span>&nbsp;</span>
		<div>
			<button style="margin: 0.25rem;" type="button" class="btn btn-secondary" (click)="close()">Close</button> 
			<!--<input class="" *ngIf="createMode" type="file" (change)="onChange($event)">-->		
			<button type="submit" class="btn btn-primary" [disabled]="!checkSave(form)" (click)="save(form)">Save</button>
		</div>
	</div>
</form>