import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputEditableComponent } from './components/input-editable/input-editable.component';
import { FormsModule } from '@angular/forms';
import { ButtonListComponent } from '@shared/components/button-list/button-list.component';
import { ButtonListPopupComponent } from './components/button-list/button-list-popup/button-list-popup.component';

@NgModule({
  declarations: [InputEditableComponent, ButtonListComponent, ButtonListPopupComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [ InputEditableComponent, ButtonListComponent ]
})
export class SharedModule { }
