import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { AppService } from '@core/services/app.service'
import { catchError, map, tap, debounce } from 'rxjs/operators'
import { Skill } from '@dashboard/models/skill'

@Injectable({
  providedIn: 'root'
})
export class SkillsService {

  apiSegment:string = "skills";

  configList:any;

  constructor(private http:HttpClient, private appService:AppService) { }

  get(): Observable<Skill[]> {
		return this.http.get(this.appService.getApiBaseUrl() + this.apiSegment).pipe(
      map((data: any) => {							
        
        this.configList = data;
        let skills:Skill[] = [];

        for(let i=0; i < data.lists.length; i++){

          if(data.lists[i].listName == "skills")
          {
            for(let j=0; j < data.lists[i].values.length; j++){
              skills.push(new Skill(data.lists[i].values[j]));
            }
            
            break;
          }

        }

        return skills;
        
      }),catchError( error => {
        console.error(error)
        return throwError( 'Something went wrong!' );
      })
    );
  }

  update(skills:any): Observable<any> {			
    
    for(let i=0; i < this.configList.lists.length; i++){

      if(this.configList.lists[i].listName == "skills")
      {        
        this.configList.lists[i].values = [];
        
        for(let j=0; j < skills.length; j++)
          this.configList.lists[i].values.push(skills[j].name)
        
          break;
      }

    }

		return this.http.put(this.appService.getApiBaseUrl() + this.apiSegment , this.configList);
  }
}
