import { Component, OnInit } from '@angular/core'
import { RuleSet } from '@dashboard/models/rule-set'
import { ConfigRuleSetService } from '@dashboard/services/config-rule-set.service'
import { RuleSetService } from '@dashboard/services/rule-set.service'
import { Condition } from '@dashboard/models/condition'
import { Validator } from '@shared/interfaces/validator'
import { NotBlank } from '@shared/classes/not-blank'
import { AppService } from '@core/services/app.service'

@Component({
  selector: 'ruleset',
  templateUrl: './ruleset.component.html',
  styleUrls: ['./ruleset.component.scss']
})
export class RulesetComponent implements OnInit {

  ruleSets:RuleSet[] = [];
  message:string = "";
  error:string = "";
  updateError:string = "";  
  nameValidators:Validator[] = [];  
  selRuleSet:RuleSet;

  constructor(private configRuleSetService:ConfigRuleSetService, private ruleSetService:RuleSetService, private appService:AppService) { }

  async ngOnInit() {

    this.loadRuleSet();

  }

  loadRuleSet(){

    this.error = "";
    this.ruleSets = [];

    this.ruleSetService.getAll().subscribe((res) => { 
      
      this.ruleSets = res;
      this.nameValidators.push(new NotBlank())      

		},(error)=>{      
			this.error = "An error has occurred. Try again ";
    });
  }

  onErrorEditName(validator:Validator, ruleSet:RuleSet){
    
    if(ruleSet.isNew && validator instanceof NotBlank)
      this.appService.removeFromArray(this.ruleSets, ruleSet);
    else
      this.updateError = validator.errorMessage;

  }
  
  saveField($event, ruleSet:RuleSet, name){
          
    this.updateError = "";
    //field.updateError = false;                  
    let found = false;

    for(let i=0; i < this.ruleSets.length; i++){
      if($event == this.ruleSets[i].ruleSetName){
        found = true;
        break;
      }
    }

    if(!found)
    {
      ruleSet.ruleSetName = $event;
      if(ruleSet.isNew)
        this.create(ruleSet);
      else
        this.update(ruleSet);
    }
    else
    {
      this.updateError = "Error. Cant update beacause field already exsits";
      this.loadRuleSet();
      //field.updateError = true;
    }

  }

  update(ruleSet:RuleSet){

    this.error = "";
    this.ruleSetService.update(ruleSet).subscribe((res) => {

		},(error)=>{
			this.error = "An error has occurred. Try again ";
    });

  }

  create(ruleSet:RuleSet){

    this.error = "";
    this.ruleSetService.create(ruleSet).subscribe((res) => {
      
      ruleSet.isNew = false;
      ruleSet.id = res.id;

		},(error)=>{
			this.error = "An error has occurred. Try again ";
    });

  }
  
  addRuleSet(){
    
    let ruleSet = new RuleSet();
    ruleSet.isNew = true;
    this.ruleSets.push(ruleSet);

  }

  async remove(ruleSet:RuleSet){
              
    if (confirm("Are you sure you want to delete this item?")) {
              
      this.error = "";
      this.ruleSetService.delete(ruleSet).subscribe((res) => {
        
        this.appService.removeFromArray(this.ruleSets, ruleSet);

      },(error)=>{
        this.error = "An error has occurred. Try again ";
      });
      
    }      
                
  }

}
