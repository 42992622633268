<div *ngIf="errorGet" class="alert alert-danger" role="alert">
	{{errorGet}} <i class="fa fa-refresh" aria-hidden="true" ></i>
</div>
<br>  
<div *ngIf="error" class="alert alert-danger" role="alert">
	{{error}} 
</div>
<h4 class="mb-3" class="page-title"> Rules Editor</h4>
<div class="row mb-3">	
	<div class="input-group col-lg-4 col-md-6">
		<label class="col-form-label" style="margin-right: 15px;">RuleSet</label>
		
		<select class="form-control" name="channelSel" [(ngModel)]="selRuleSet" (change)="changeRuleSet()">			
			<!--<option value="EmailRuleSet">EmailRuleSet</option>
			<option value="ChatRuleSet">ChatRuleSet</option>-->
			<option *ngFor="let ruleSet of ruleSets" [ngValue]="ruleSet" >{{ruleSet.ruleSetName}}</option>
		</select>
	
	</div>
	<div class="col-lg-8 col-md-6 mt-2 mt-sm-0" style="justify-content: flex-end; display: flex;">
		<div class="custom-file" id="customFile" style="max-width:220px">
			<input #fileInpu type="file" class="custom-file-input" id="exampleInputFile" (change)="importRuleset($event)" (click)="fileInpu.value=null">
			<label class="custom-file-label" for="exampleInputFile">
				Import Rule Set
			</label>
		</div>
		<button class="btn btn-secondary ml-2" title="Export Ruleset" (click)="exportRuleSet()">Export</button><i class="fas fa-file-export"></i>
	</div>
	
</div>

<div *ngIf="pendingRulesToSave() > 0" class="row mt-2 mb-3">
	<div class="col">
		<div style="padding: 10px;background: #efe;color: green;border: 1px solid #86B245;">
			Exists {{ pendingRulesToSave() }} rules pending to save. Are you want save the changes? 
			<button class="btn btn-primary ml-2" (click)="savePendingRules()">Save</button>
			<button class="btn btn-secondary ml-2" (click)="discardPendingRules()">Discard</button>
		</div>		
	</div>
</div>

<div class="row">
	<div class="col">
<!-- selRuleSet.ruleOrder | json -->
		
		<!--<table class="table borderless">
			<thead class="thead-light">
			<tr>				
				<th id="tmp1" style="width:200px">Name</th>
                <th id="tmp2" >Description</th>
                <th id="tmp3" >Stop Evaluation on Match</th>
				<th id="tmp4" style="width:200px; text-align: center;">Config Rules</th>		
				<th id="tmp5" style="width:50px"></th>		
			</tr>
			</thead>
			<tbody>
				
				<tr *ngFor="let rule of selRuleSet.ruleList; let index = index" [ngClass]="{'pending-save': rule.pendingToSave, 'moved': rule.moved}">
                    
					<td>
						<input-editable [params]="{value:rule.name, width:'100%'}"
							(onSave)="saveField($event, rule, 'name')" [iniFocus]="rule.isNew"></input-editable>
					</td>
					<td>
						<input-editable [params]="{value:rule.description, width:'100%'}"
						(onSave)="saveField($event, rule, 'description')"></input-editable>
                    </td>
					<td style="width:40px; text-align: center;"><input type="checkbox" [(ngModel)]="rule.stopEvalWhenMatched" 
						(change)="changeStopEvalWhenMatched()"/></td>
					<td style="vertical-align:middle;text-align: center;">
						<i class="fa fa-cog" aria-hidden="true" style="cursor: pointer;" (click)="setupRule(rule)" ></i>
					</td>
					<td>
						<i title="Up" class="fa fa-caret-up arrow" aria-hidden="true"  [ngClass]="{'disabled': !(index > 0)}" (click)="index > 0 && moveUp(rule)"></i>
						<i title="Down" class="fa fa-caret-down arrow" aria-hidden="true" [ngClass]="{'disabled': (index + 1 >= selRuleSet.ruleList.length )}" 
							(click)="!(index + 1 >= selRuleSet.ruleList.length ) && moveDown(rule)"></i>
					</td>
				</tr>
				
			</tbody>
			</table>-->
			<div cdkDropList class="example-list mb-3" (cdkDropListDropped)="drop($event)">

				<div class="box-header example-box">

					<div class="col2 col-name"> Name </div>
					<div class="col2 col-desc"> Description </div>
					<div class="col2 col-changestop"> Stop Evaluation on Match </div>
					<div class="col2 col-config"> Config Rules </div>
					<div class="col2 col-order">  </div>

				</div>

				<div class="example-box" *ngFor="let rule of selRuleSet.ruleList; let index = index" cdkDrag>

					<div class="col2 col-name">
						<input-editable [params]="{value:rule.name, width:'100%'}"
						(onSave)="saveField($event, rule, 'name')" [iniFocus]="rule.isNew"></input-editable>
					</div>
			
					<div class="col2 col-desc">
						<input-editable [params]="{value:rule.description, width:'100%'}"
						(onSave)="saveField($event, rule, 'description')"></input-editable>
					</div>

					<div class="col2 col-changestop">
						<input type="checkbox" [(ngModel)]="rule.stopEvalWhenMatched" (change)="changeStopEvalWhenMatched()"/>
					</div>

					<div class="col2 col-config" style="vertical-align:middle;text-align: center;">
						<i class="fa fa-cog" aria-hidden="true" style="cursor: pointer;" (click)="setupRule(rule)" ></i>
					</div>

					<div class="col2 col-order">

						<div class="example-handle" cdkDragHandle>
							<svg width="24px" fill="currentColor" viewBox="0 0 24 24">
								<path
									d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
								</path>
								<path d="M0 0h24v24H0z" fill="none"></path>
							</svg>
						</div>
						
					</div>
			
				</div>			
			</div>
			
			<div style="display: flex;justify-content: space-between;">
				<button (click)="addRule()" class="btn btn-secondary"> <i class="fa fa-plus"></i>  Add Rule</button>

				<div classs="">
					<button [disabled]="!pendingChanges" (click)="undo()" class="btn btn-secondary"> <i class="fa fa-undo"></i> </button>
					<button [disabled]="!pendingChanges" (click)="updateRuleSet()" class="btn btn-primary ml-2"> Save</button>
				</div>
			</div>      			

			
	</div>
</div>
