import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TemplateComponent } from '@dashboard/components/template/template.component';
import { Template } from '@dashboard/models/template';
import { TemplateService } from '@dashboard/services/template.service';
import { AppService } from '@core/services/app.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  templates:Template[] = [];
  templatesBKP:Template[] = [];
  errorGet:string = "";
  error:string = "";
  selectedTemplates:Template[] = [];

  constructor( private modalService: NgbModal, private templateService:TemplateService, private appService:AppService ) { }

  ngOnInit(): void {

    this.getTemplates();
  }

  selectTemplate(event, template:Template){
        
    if(event.target.checked)
      this.selectedTemplates.push(template);
    else{
      for(let i=0; i < this.selectedTemplates.length; i++){
        if(this.selectedTemplates[i].templateId == template.templateId)
          this.selectedTemplates.splice(i, 1);
      }
    }

  }

  isTemplateChecked(template){
    
    let isChecked = false;

    for(let i=0; i < this.selectedTemplates.length; i++){
      if(this.selectedTemplates[i].templateId == template.templateId)
      {
        isChecked = true;
        break;
      }
    }

    return isChecked;
  }

  selectAllTemplates(event){

    this.selectedTemplates = [];
    console.log("selecte item - " + event.target.checked)

    if(event.target.checked)
    {      
      for(let i=0; i < this.templates.length; i++){        
          this.selectedTemplates.push(this.templates[i]);
      }
    }

  }
  saveTemplateField($event, template:Template, field:string){

    template[field] = $event;
    this.error = "";
    this.templateService.update(template).subscribe((res) => {
                             
      this.templateService.replaceEle(this.templates, template.templateId,  template);
      
    },(err)=>{			
      if(err.error && err.error.error)
        this.error = err.error.error;
      else
        this.error = this.appService.serverError;		
    },()=>{});

  }

  getTemplates(){

    this.errorGet = "";
    this.templateService.getAll().subscribe((res) => { 
			
      this.templates = res;
      this.templates.sort((a,b) => (a.templateName > b.templateName) ? 1 : ((b.templateName > a.templateName) ? -1 : 0))

      this.templatesBKP = JSON.parse(JSON.stringify(this.templates));
   
		},(error)=>{
			this.errorGet = "An error has occurred. Try again ";
    });
        
  }
  export(){    

    for(let i=0; i < this.selectedTemplates.length; i++){
      let tem = {templateName:this.selectedTemplates[i].templateName, description: this.selectedTemplates[i].description,
        template: this.selectedTemplates[i].template}
      let text = JSON.stringify(tem, null, 4);
        this.saveFile(text, this.selectedTemplates[i].templateName + ".json");
    }
       
  }

  private saveFile(text:string, filename:string){    
    this.appService.saveFile(text, filename);
  }

  addTemplate(){
    const modalRef = this.modalService.open(TemplateComponent, { windowClass : "template-modal"});
    modalRef.componentInstance.templates = this.templates;
    //this.templates = JSON.parse(JSON.stringify(this.templatesBKP));
  }

  editTemplate(template:Template){
    const modalRef = this.modalService.open(TemplateComponent, { windowClass : "template-modal"});
    modalRef.componentInstance.templates = this.templates;
    modalRef.componentInstance.template = template;
    //this.templates = JSON.parse(JSON.stringify(this.templatesBKP));
  }

}
