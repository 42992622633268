import { Component, OnInit, Input, OnDestroy  } from '@angular/core';
import { Template } from '@dashboard/models/template'
import { NgForm } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from '@dashboard/services/template.service';
import { AppService } from '@core/services/app.service';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, OnDestroy  {
  
  error:string = "";
  createMode:boolean = true;
  modalTitle:string = "";
  @Input() selTemplate:Template = new Template();
  @Input() template:Template;
  @Input() templates:Template[];
  fileContent: string = '';
  fileName: string = "";
  editor: Editor;
  htmlTxt:string;

  constructor(private modalService: NgbModal, private templateService:TemplateService, private appService:AppService) { }  

  ngOnInit(): void {        

    if(this.template){
      this.createMode = false;
      this.selTemplate = JSON.parse(JSON.stringify(this.template));      
    }

    this.editor = new Editor();    
    //console.log("Template", this.selTemplate);
    this.htmlTxt = this.selTemplate.template;

  }

  onChangeEditor(html: any) {
        
    console.log("onCHange->", html)
    this.selTemplate.template = html;
    console.log("TEMPLATE" , this.selTemplate)

  }


  ngOnDestroy(): void {
    this.editor.destroy();
  }

  public onChange(evt): void {

    this.fileName = evt.target.files[0].name;            
    let file = evt.target.files[0];

    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = function(x) {      
      let res:string = fileReader.result as string;
      try{
        let obj: Template = JSON.parse(res);

        if(self.createMode)
        {
          self.selTemplate.templateName = obj.templateName;
          self.selTemplate.isHtml = obj.isHtml;
          self.selTemplate.description = obj.description;
        }

        self.htmlTxt =  "<html>" + obj.template + "</html>";
        //self.editor.setContent("JIJI");
        self.selTemplate.template = obj.template;

      }catch(e){console.log(e); self.error = "Incorrect format"}
    }
    fileReader.readAsText(file);
  }

  close(){
    this.modalService.dismissAll();
  }

  delete(){

    if(confirm("Are you sure?")){

      this.templateService.delete(this.selTemplate).subscribe((res) => {
            
        this.modalService.dismissAll();          
        
        for(let i=0; i < this.templates.length; i++){
          if(this.templates[i].templateId ==this.selTemplate.templateId)
          {			
            this.templates.splice(i, 1);
            break;
          }
        }
        
      },(err)=>{			
        if(err.error && err.error.error)
          this.error = err.error.error;
        else
          this.error = this.appService.serverError;		
      },()=>{});	
    }
  }
  
  checkSave(form:NgForm){
    if(!form.valid)
      return false;
    else
      return true;
  }

  save(form){
    //if(form.valid)
    {
      
      var template:Template = Object.assign({}, this.selTemplate);
      let self = this;           

      if(this.createMode){
                
        this.templateService.create(template).subscribe((res) => { 
          console.log("->" + JSON.stringify(res))          
          self.modalService.dismissAll();                    
          self.templates.push(res)
          
        },(err)=>{			
          if(err.error && err.error.error)
            this.error = err.error.error;
          else
            this.error = this.appService.serverError;
        },()=>{          
          
        });	

      }
      else
      {

        this.templateService.update(template).subscribe((res) => {
          
          this.modalService.dismissAll();          
          this.templateService.replaceEle(this.templates, template.templateId,  template);
          
        },(err)=>{			
          if(err.error && err.error.error)
            this.error = err.error.error;
          else
            this.error = this.appService.serverError;		
        },()=>{});	
        
      }
    }
  }
}
