<div *ngIf="message" class="alert alert-info">{{message}}</div>
<div *ngIf="error" class="alert alert-danger">{{error}}</div>
<!--<div *ngIf="updateError" class="alert alert-danger">{{updateError}}</div>-->

<h2 class="mt-4 mb-4">RuleSets</h2>
  <div class="row">
		<div class="col">
      <!-- ruleSets | json -->
			<table class="table borderless">
				<thead class="thead-light">
					<tr>				
						<th id="tmp1" style="width:200px">Name</th>						
						<th id="tmp1">Email</th>
						<th id="tmp1">Chat</th>
						<th id="tmp1"></th>							
					</tr>
				</thead>
				<tbody>          
					<tr *ngFor="let ruleSet of ruleSets">
            <!--[ngClass]="{'error':ruleSet.updateError}"-->
						<td>
							<input-editable [params]="{value:ruleSet.ruleSetName, width:'100%', disabled: false}" [iniFocus]="ruleSet.isNew"
							(onSave)="saveField($event, ruleSet, 'name')" [validators]="nameValidators" (onError)="onErrorEditName($event, ruleSet)"></input-editable>              
						</td>						
						<td style="width:40px; text-align: center;">
              <input name="enabledForEmail" type="checkbox" [(ngModel)]="ruleSet.email" (change)="update(ruleSet)"/>
            </td>
						<td style="width:40px; text-align: center;">
              <input name="enabledForChat" type="checkbox" [(ngModel)]="ruleSet.chat" (change)="update(ruleSet)"/>
            </td>

            <td style="width:40px; text-align: center;">
              <i class="fa fa-trash-o" (click)="remove(ruleSet)"></i>
            </td>
					</tr>
			</tbody>
		</table>                    
		<button (click)="addRuleSet()" class="btn btn-primary"> <i class="fa fa-plus"></i>  Add RuleSet</button>

	</div>
</div>