import { Component, OnInit } from '@angular/core'
import { SkillsService } from '@dashboard/services/skills.service'
import { RuleSetService } from '@dashboard/services/rule-set.service'
import { Validator } from '@shared/interfaces/validator'
import { NotBlank } from '@shared/classes/not-blank'
import { AppService } from '@core/services/app.service'
import { Skill } from '@dashboard/models/skill'

@Component({
  selector: 'skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  skills:Skill[] = [];
  message:string = "";
  error:string = "";
  updateError:string = "";
  usedSkills:Skill[]=[];
  nameValidators:Validator[] = [];
  labelValidators:Validator[] = [];

  constructor(private skillsService:SkillsService, private ruleSetService:RuleSetService, private appService:AppService) { }

  async ngOnInit() {            

    this.loadSkills();

  }


  loadSkills(){
    
    this.error = "";    

    this.skillsService.get().subscribe((skills:Skill[]) => {    
               
      this.skills = skills;

      this.nameValidators.push(new NotBlank())
      this.labelValidators.push(new NotBlank())

    },(error)=>{      
      this.error = "An error has occurred. Try again ";
    });

  }


  onErrorEditName(validator:Validator, skill:Skill){
    
    if(skill.isNew && validator instanceof NotBlank)
      this.appService.removeFromArray(this.skills, skill);
    else
      this.updateError = validator.errorMessage;

  }

  onErrorEditLabel(validator:Validator, skill:Skill){    
    this.updateError = validator.errorMessage;
  }
  
  saveField($event, skill, name){
    
    if(name == "name")
    {       
        this.updateError = "";
        skill.updateError = false;
                      
        let found = false;

        for(let i=0; i < this.skills.length; i++){
          if($event == this.skills[i].name){
            found = true;
            break;
          }
        }

        if(!found)
        {
          skill[name] = $event;        
          this.update();  
        }
        else
        {
          this.updateError = "Error. Cant update beacause field already exsits";
          //this.loadFields();
          skill.updateError = true;
        }                                                          

    }
    else{
      skill[name] = $event;        
      this.update(); 
    }
    
  }

  update(){

    let skillsDB:any[] = this.skills.map(item => {
      item.isNew = false;
      return {name:item.name}
    })
    
    this.skillsService.update(skillsDB).subscribe((res) => {
      
		},(error)=>{
      
			this.error = "An error has occurred. Try again ";
    });
  }

  searchSkillName(name){

    for(let i=0; i < this.usedSkills.length; i++){
      if(this.usedSkills[i].name == name)
        return this.usedSkills[i];
    }

    return false;
  }

  existsSkillName(name){
    if(this.searchSkillName(name))
      return true;
    else
      return false;
  }
  
  addSkill(){
    let skill = new Skill();
    skill.isNew = true;
    this.skills.push(skill);
  }

  async remove(skill:Skill){
              
    if (confirm("Are you sure you want to delete this item?")) {
      
        //this.usedSkills = await this.ruleSetService.getUsedSkills();

        if(!this.searchSkillName(skill.name))
        {          
          this.appService.removeFromArray(this.skills, skill);
          this.update();
        }
        else this.error = "Can't delete this item beacuse already exists"
      
    }      
                
  }

}
