import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgJsonEditorModule } from 'ang-jsoneditor' 

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { TemplateComponent } from './components/template/template.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { RulesDesignerComponent } from './pages/rules-designer/rules-designer.component';
import { SetupRuleComponent } from './components/ruleset/setup-rule/setup-rule.component';
import { RuleComponent } from './components/ruleset/rule/rule.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { ConditionsFieldsComponent } from './components/configuration/conditions-fields/conditions-fields.component';
import { ImportRuleSetComponent } from './components/ruleset/import-rule-set/import-rule-set.component';
import { ConditionsSetComponent } from './components/ruleset/conditions-set/conditions-set.component';
import { SkillsComponent } from './components/configuration/skills/skills.component';
import { DictionaryComponent } from './components/configuration/dictionary/dictionary.component';
import { ListsComponent } from './components/configuration/lists/lists.component';
import { RulesetComponent } from './components/configuration/ruleset/ruleset.component';
import { ActionsComponent } from './components/configuration/actions/actions.component';
import { SetupFieldComponent } from './components/configuration/actions/setup-field/setup-field.component';
import { NgxEditorModule } from 'ngx-editor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';


@NgModule({
  declarations: [DashboardComponent, TemplatesComponent, TemplateComponent, RulesDesignerComponent, SetupRuleComponent, RuleComponent, ConfigurationComponent, ConditionsFieldsComponent, ImportRuleSetComponent, ConditionsSetComponent, SkillsComponent, DictionaryComponent, ListsComponent, RulesetComponent, ActionsComponent, SetupFieldComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    SharedModule,
    NgJsonEditorModule,
    NgxEditorModule,
    DragDropModule,
    BrowserAnimationsModule,
    MatTableModule
  ]
})
export class DashboardModule { }
