export class ListElement {
  name:string;
  values:any[] = [];

  isNew:boolean = false;

  constructor(){

  }
}
