import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppService } from '@core/services/app.service';
import { RuleSet } from '@dashboard/models/rule-set';
import { catchError, map, tap, debounce } from 'rxjs/operators';
import { Condition } from '@dashboard/models/condition'
import { Action } from '@dashboard/models/action'
import { Rule } from '@dashboard/models/rule'
import { ActionField } from '@dashboard/models/action-field'
import { ConditionNode } from '@dashboard/models/condition-node'
import { ConditionNodesParent } from '@dashboard/models/condition-nodes-parent'
import { Skill } from '@dashboard/models/skill'

@Injectable({
  providedIn: 'root'
})
export class RuleSetService {
  
	apiSegment:string = "ruleset";
  lastFindParent:ConditionNodesParent;

  constructor(private http:HttpClient, private appService:AppService) { }

  get(name:string): Observable<any> {			
		let self = this;
		
		return this.http.get(this.appService.getApiBaseUrl() + this.apiSegment + "?name=" + name).pipe(
				map((data: any) => {							
          
          return self.parseRuleSet(data[0]);
					
				}),catchError( error => {
          console.error(error)
					return throwError( 'Something went wrong!' );
				})
      );
  }
  
  getAll(): Observable<any> {			
		let self = this;
		
		return this.http.get(this.appService.getApiBaseUrl() + this.apiSegment + "?name=*").pipe(
				map((data: any) => {

          let ruleSets:RuleSet[] = [];
          
          for(let i=0; i < data.length; i++){
            ruleSets.push(self.parseRuleSet(data[i]))
          }

          ruleSets.sort((a,b) => (a.ruleSetName > b.ruleSetName) ? 1 : ((b.ruleSetName > a.ruleSetName) ? -1 : 0))

          return ruleSets;
					
				}),catchError( error => {
          console.error(error)
					return throwError( 'Something went wrong!' );
				})
      );
  }

  create(ruleSet:RuleSet): Observable<any> {				
    return this.http.post  (this.appService.getApiBaseUrl() + this.apiSegment, this.prepareToDB(ruleSet));
	}

	update(ruleSet:RuleSet): Observable<any> {						
		return this.http.put(this.appService.getApiBaseUrl() + this.apiSegment + "/"  + ruleSet.id, this.prepareToDB(ruleSet));
  }
  
  delete(ruleSet:RuleSet): Observable<any> {		
				
		return this.http.delete(this.appService.getApiBaseUrl() + this.apiSegment + "/" + ruleSet.id);
  }

  replaceEle(ruleSets:RuleSet[], id, ruleSet){
	
		for(let i=0; i < ruleSets.length; i++){
		  if(ruleSets[i].id ==id)
		  {			
			  ruleSets[i] = ruleSet;
			  break;
		  }
		}
	}

	checkValidRuleSet(ruleSetDB){
		
    if(!ruleSetDB.hasOwnProperty('ruleOrder') || !ruleSetDB.hasOwnProperty('ruleSetName') || !ruleSetDB.hasOwnProperty('ruleList'))
        return false;

    return true;
	}

	exploreConditionsTree(conditionNodesParent:ConditionNodesParent, conditionsLevelDB:any){
    	
	  let conditionType = this.isNode(conditionsLevelDB);

    if(conditionType)
    {										      
      conditionNodesParent.conditionType = conditionType;
      let arrConditions = conditionsLevelDB[conditionType];

      for(let i=0; i < arrConditions.length; i++){

        let condType = this.isNode(arrConditions[i]);
        if(condType)
        {
          var condNodesParent = new ConditionNodesParent(condType);
          
          conditionNodesParent.conditionNodes.push(condNodesParent);
          this.exploreConditionsTree(condNodesParent, arrConditions[i]);
        }
        else{
          let condition:Condition = new Condition();
          
          condition.name = arrConditions[i]["name"];
          condition.operator = arrConditions[i]["operator"];
          condition.value = arrConditions[i]["value"];
    
          conditionNodesParent.conditionNodes.push(condition);
        }
      }        
    }    
			 
  }
  
  cloneRuleSet(ruleSet:RuleSet, cloneRuleSet:RuleSet):RuleSet{
    
    //let cloneRuleSet = new RuleSet();

    cloneRuleSet.id = ruleSet.id;
    cloneRuleSet.chat = ruleSet.chat;
    cloneRuleSet.email = ruleSet.email;
    cloneRuleSet.ruleOrder = ruleSet.ruleOrder;
    cloneRuleSet.ruleSetName = ruleSet.ruleSetName;

    cloneRuleSet.ruleList = [];
    
    for(let i=0; i < ruleSet.ruleList.length; i++){

      let rule = new Rule();
      this.cloneNode(ruleSet.ruleList[i], rule);
      cloneRuleSet.ruleList.push(rule);

    }

    return cloneRuleSet;

  }

  cloneRule(conditionNode:ConditionNode, newConditionNode:ConditionNode){
    this.cloneNode(conditionNode, newConditionNode);
  }

  cloneNode(node:any, newNode:any){
                    
    for(let key in node) {
    
        if(typeof node[key] == "object"){

          if(Array.isArray(node[key]))
            newNode[key] = [];
          else
          {
            let className = node[key].constructor.name;            
            
            if(className == "Action")
              newNode[key] = new Action();
            else if(className == "ActionField")
              newNode[key] = new ActionField();
            else if(className == "ConditionNodesParent")
              newNode[key] = new ConditionNodesParent();
            else if(className == "Condition")
              newNode[key] = new Condition();
            else if(className == "Rule")
              newNode[key] = new Rule();
            else
              newNode[key] = eval("new " + node[key].constructor.name + "()");
          }

          this.cloneNode(node[key], newNode[key]);
        }
        else
          newNode[key] = node[key];
    }
    
  }

  getParent(rule:Rule, node:any):ConditionNodesParent{
    
    this.searchParentOnTree(node, rule.conditionNodesParent);    
    return this.lastFindParent;

	}

  searchParentOnTree(node:any, conditionNodesParent:ConditionNodesParent){
            
    for(let i=0; i < conditionNodesParent.conditionNodes.length; i++){

      if(node == conditionNodesParent.conditionNodes[i])
      {
        this.lastFindParent = conditionNodesParent;
      }
      else if(conditionNodesParent.conditionNodes[i] instanceof ConditionNodesParent)
        this.searchParentOnTree(node, <ConditionNodesParent>conditionNodesParent.conditionNodes[i])
            
    }

  }

  getUnusedParents(rule:Rule, nodeDel:any):ConditionNodesParent[]{

    let unUsedParents:ConditionNodesParent[] = [];
    
    this.searchUnusedParents(rule, nodeDel, unUsedParents)
    
    return unUsedParents;

  }

  searchUnusedParents(rule:Rule, nodeDel:any, unUsedParents:ConditionNodesParent[]){

    let parent = this.getParent(rule, nodeDel);
    
    if(parent.conditionNodes.length == 1 && !parent.isRoot)
    {
      unUsedParents.push(parent);
      this.searchUnusedParents(rule, parent, unUsedParents);
    }

  }

  removeNode(baseNode:any, node:any){
        
    let index = 0;
    for(let key in baseNode) {
    
      if(typeof baseNode[key] == "object"){

        if(baseNode[key] == node)
        { 
          if(Array.isArray(baseNode))          
            baseNode.splice(index, 1);          
          else
            delete baseNode[key];
        }
        else
          this.removeNode(baseNode[key], node);
      }
      index++;
    }
    
  }

  isNode(level:any){
    
    let levelFields = Object.keys(level);
    let conditionType = levelFields[0];
    let arrType = ["any", "all", "none"];

    if(levelFields.length == 1 && arrType.indexOf(levelFields[0]) > -1)
      return conditionType;
    else
      return false;

  }
	
  parseRuleSet(ruleSetDB:any):RuleSet{

		let ruleSet = new RuleSet();		
	
		ruleSet.ruleSetName = ruleSetDB.ruleSetName;
    
    if(ruleSetDB.email === true || ruleSetDB.email === false)
      ruleSet.email = ruleSetDB.email;

    if(ruleSetDB.chat === true || ruleSetDB.chat === false)
      ruleSet.chat = ruleSetDB.chat;

		ruleSet.id = ruleSetDB.id;
		
    if(ruleSetDB.ruleOrder)
    {
      let arrRuleOrders = [];
      let ruleNames = ruleSetDB.ruleOrder.split(",");

      for(let i=0; i < ruleNames.length; i++){
        
        let ruleDBitem = ruleSetDB.ruleList[ruleNames[i]];

        if(ruleDBitem){
          let rule = new Rule();
          rule.name = ruleNames[i];
          arrRuleOrders.push(rule.name);
          rule.description = ruleDBitem.description;
          rule.stopEvalWhenMatched = ruleDBitem.stopEvalWhenMatched;
          rule.actionOrder = ruleDBitem.actions.actionOrder;
      
          this.exploreConditionsTree(rule.conditionNodesParent, ruleDBitem.conditions);
      
          if(Object.keys(ruleDBitem.actions.actionList).length > 0){
            let actionNames = ruleDBitem.actions.actionOrder.split(",");
        
            for(let i=0; i < actionNames.length; i++){
        
              let actionName:string = actionNames[i];
              
              if(ruleDBitem.actions.actionList[actionName])
              {
                let actionDBitem = ruleDBitem.actions.actionList[actionName];
                let action = new Action();
                action.name = actionName;
          
                for(let i=0; i < actionDBitem.fields.length; i++){
                  let actionField = new ActionField();
                  actionField.name = actionDBitem.fields[i]["name"];
                  actionField.value = actionDBitem.fields[i]["value"];
                  action.fields.push(actionField)
                }
                
                rule.actions.push(action);
              }
            }
          }
          
          ruleSet.ruleList.push(rule);
        }
        else{
          console.error("RuleName Index doesnt exists -> " + ruleNames[i]);
        }
        
      }
      ruleSet.ruleOrder = arrRuleOrders.join(",");
    }
    else
      ruleSet.ruleOrder = "";

		return ruleSet;
	}
  
  exploreTreeToBD(conditionsDB:any, conditionNodesParent:ConditionNodesParent){

    let condType = conditionNodesParent.conditionType;
    conditionsDB[condType] = [];

    for(let i=0; i < conditionNodesParent.conditionNodes.length; i++){
      let item = {};
      conditionsDB[condType].push(item)

      if(conditionNodesParent.conditionNodes[i] instanceof ConditionNodesParent)                    
        this.exploreTreeToBD(item, <ConditionNodesParent>conditionNodesParent.conditionNodes[i])      
      else if(conditionNodesParent.conditionNodes[i] instanceof Condition){        
        let condition = <Condition>conditionNodesParent.conditionNodes[i];
        item["name"] = condition.name;
        item["operator"] = condition.operator;
        item["value"] = condition.value;        
      }      
      
    }

  }

  extractConditionsFromParent(conditions:Condition[], conditionNodesParent:ConditionNodesParent){
        
    for(let i=0; i < conditionNodesParent.conditionNodes.length; i++){
          
      if(conditionNodesParent.conditionNodes[i] instanceof ConditionNodesParent)                    
        this.extractConditionsFromParent(conditions, <ConditionNodesParent>conditionNodesParent.conditionNodes[i])      
      else if(conditionNodesParent.conditionNodes[i] instanceof Condition){        
        let condition = <Condition>conditionNodesParent.conditionNodes[i];        
        conditions.push(condition)
      }
    }
    
  }

  extractUsedConditionsFromRuleSet(ruleSet:RuleSet){

    let conditions:Condition[] = [];

    for(let i=0; i < ruleSet.ruleList.length; i++)
      this.extractConditionsFromParent(conditions, ruleSet.ruleList[i].conditionNodesParent);
      
    return conditions;

  }

  extractUsedConditionsFromRuleSetFromArray(ruleSets:RuleSet[]){

    let conditions:Condition[] = [];

    for(let i=0; i < ruleSets.length; i++){      
      conditions = conditions.concat(this.extractUsedConditionsFromRuleSet(ruleSets[i]))
    }

    return conditions;

  }

  extractUsedSkillsFromRuleSet(ruleSet:RuleSet){

    let usedSkills:Skill[] = [];

    for(let i=0; i < ruleSet.ruleList.length; i++){

      for(let j=0; j < ruleSet.ruleList[i].actions.length; j++){        
        if(ruleSet.ruleList[i].actions[j].name == "routeToAgent"){

          for(let k=0; k < ruleSet.ruleList[i].actions[j].fields.length; k++){
            if(ruleSet.ruleList[i].actions[j].fields[k].name == "skill")
              usedSkills.push( new Skill(ruleSet.ruleList[i].actions[j].fields[k].value));
          }
        }
      }
    }

    return usedSkills;

  }

  prepareToDB(ruleSet:RuleSet){
		
    let ruleSetDB:any = {}
    
    console.log("prepareToDB ruleSet")
    console.log(ruleSet)
				
    ruleSetDB.ruleSetName = ruleSet.ruleSetName;
    ruleSetDB.ruleOrder = ruleSet.ruleOrder;
		ruleSetDB.ruleList = {};
    ruleSetDB.email = ruleSet.email;
    ruleSetDB.chat = ruleSet.chat;

		for(let i=0; i < ruleSet.ruleList.length; i++){
			let rule:Rule = ruleSet.ruleList[i];

			let ruleName = rule.name;
			ruleSetDB.ruleList[ruleName] = {};
			ruleSetDB.ruleList[ruleName].stopEvalWhenMatched = rule.stopEvalWhenMatched;
			ruleSetDB.ruleList[ruleName].description = rule.description;
			ruleSetDB.ruleList[ruleName].conditions = {};
			let conditionType = rule.conditionType;
			ruleSetDB.ruleList[ruleName].conditions[conditionType] = [];

      let conditionsDB = {}
      ruleSetDB.ruleList[ruleName].conditions = conditionsDB;
      this.exploreTreeToBD(conditionsDB, rule.conditionNodesParent);

			ruleSetDB.ruleList[ruleName].actions = {};
			let actionList = ruleSetDB.ruleList[ruleName].actions["actionList"] = {}
			ruleSetDB.ruleList[ruleName].actions.actionOrder = rule.actionOrder;			
			
			for(let j=0; j < rule.actions.length; j++){
				let action:Action = rule.actions[j];
				actionList[action.name] = {}
				actionList[action.name].fields = [];

				for(let k=0; k < action.fields.length;k++){
					let field:ActionField = action.fields[k];;
					actionList[action.name].fields.push({name:field.name, value:field.value});
				}
			}

    }  	

		return ruleSetDB;
	}

  async getUsedConditions(){

    try{

      let res1:any = await this.getAll().toPromise();      
      return this.extractUsedConditionsFromRuleSetFromArray(res1);

    }catch(e){
      console.log(e)
      throw new Error(e);
    }

  }


}
