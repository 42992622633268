<div *ngIf="errorGet" class="alert alert-danger" role="alert">
	{{errorGet}} <i class="fa fa-refresh" aria-hidden="true" (click)="getTemplates()"></i>
</div>
<br>  
<div *ngIf="error" class="alert alert-danger" role="alert">
	{{error}} 
</div>

<div class="row">
	<div class="col">
		<button [disabled]="selectedTemplates.length==0" (click)="export()" class="btn btn-secondary mb-2"> <i class="fa fa-plusdss"></i>  Export </button>
		<br>
		<table class="table borderless">
			<thead class="thead-light">
			<tr>
				<th id="tmp1" style="width:40px"><input type="checkbox" (change)="selectAllTemplates($event)"/></th>
				<th id="tmp2" style="width:200px">Name</th>
				<th id="tmp3">Description</th>
				<th id="tmp4" style="width:200px; text-align: center;">Template</th>				
			</tr>
			</thead>
			<tbody>
				<tr *ngFor="let template of templates;">
					<td> <input type="checkbox" [checked]="isTemplateChecked(template)" (change)="selectTemplate($event, template)"/></td>
					<td>
						<input-editable [params]="{value:template.templateName, width:'100%'}" 
						(onSave)="saveTemplateField($event, template, 'templateName')"></input-editable>
					</td>
					<td>
						<input-editable [params]="{value:template.description, width:'100%'}" 
						(onSave)="saveTemplateField($event, template, 'description')"></input-editable>
					</td>
					<td style="vertical-align:middle;text-align: center;">
						<i class="fa fa-commenting" aria-hidden="true" style="cursor: pointer;" (click)="editTemplate(template)" ></i>
					</td>          
				</tr>
			</tbody>
			</table>                    
			<button (click)="addTemplate()" class="btn btn-primary"> <i class="fa fa-plus"></i>  Add template</button>

	</div>
</div>


