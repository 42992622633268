import { Component, OnInit, Input, ComponentRef, EventEmitter, Output, ViewChild } from '@angular/core'
import { ElementRef, HostListener, AfterViewInit } from "@angular/core"
import { AppService } from '@core/services/app.service'

interface Position{
  left:number;
  top:number;    
}

@Component({
  selector: 'button-list-popup',
  templateUrl: './button-list-popup.component.html',
  styleUrls: ['./button-list-popup.component.scss']
})

export class ButtonListPopupComponent implements OnInit {

  @Input() position: any;
  @Input() popupComponentRef: ComponentRef<any>;
  @Input() list:any[] = [];
  @Input() element:any;
  @Input() index:number;

  @Output() onClose = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<any>();

  firstLoad:boolean = false;    
  error:string = "";  
  createMode:boolean = true;
  
  @ViewChild('inputValue') inputOne: ElementRef;

  constructor(private elementRef: ElementRef, private appService:AppService) { }

  ngAfterViewInit() {

    //if(!this.createMode)
      this.inputOne.nativeElement.focus();
  }

  ngOnInit(): void {
         
  }

  selectAttr(attr){
    
    this.save();
    
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;

    if(charCode == 13)
    {          
      this.save();
      event.preventDefault();
      return false;
    }

    return true;
    /*if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }*/
  }

  save(){
    
    this.onSave.emit({element:this.element, index: this.index});  

  }
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
            
      if(this.firstLoad)      
      {
        //this.elementRef.nativeElement.remove();
        console.log("OUTSIDE")
        //this.popupComponentRef.destroy();
        this.onClose.emit();
      } 
      
      this.firstLoad = true;
    }    
  }

}
