<div *ngIf="message" class="alert alert-info">{{message}}</div>
<div *ngIf="error" class="alert alert-danger">{{error}}</div>
<div *ngIf="updateError" class="alert alert-danger">{{updateError}}</div>

<h2 class="mt-3 mb-4">Skills</h2>

  <div class="row">
		<div class="col">
      
			<!--<table class="table borderless">
				<thead class="thead-light">
					<tr>				
						<th id="tmp" style="width:200px">Name</th>						
						<th id="tmp1"></th>
					</tr>
				</thead>
				<tbody>          
					<tr *ngFor="let skill of skills">
						<td>
							<input-editable [params]="{value:skill.name, width:'100%', disabled: existsSkillName(skill.name)}" [iniFocus]="skill.isNew"
							(onSave)="saveField($event, skill, 'name')" [validators]="nameValidators" (onError)="onErrorEditName($event, skill)"></input-editable>              
						</td>						

            <td style="width:40px; text-align: center;">
              <i class="fa fa-trash-o" [ngClass]="{'disabled': existsSkillName(skill.name)}" (click)="!existsSkillName(skill.name) && remove(skill)"></i>
            </td>
					</tr>
			</tbody>
		</table>                    
		<button (click)="addSkill()" class="btn btn-primary"> <i class="fa fa-plus"></i>  Add Skill</button>-->

	</div>
</div>