import { ConditionOperator } from "./condition-operator";

export class ConditionField {
  
  name:string = "";
  label:string = "";
  chat:boolean = true;
  email:boolean = true;	
  isNew:boolean = false;
  updateError:boolean = false;
  operators:ConditionOperator[] = []

	constructor(){
			
	}
}
