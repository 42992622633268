import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { Router, CanActivateChild } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ClientAuthGuard implements CanActivate, CanActivateChild {

  constructor(
		private router: Router){ 
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.chekUser(childRoute, state);
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.chekUser(route, state);
  }
  
  private chekUser(route:any, state:any): boolean {
		
        
        return true;    

		/*if (this.authenticationService.isLogin()) 
			return true;            
		else {
		
			this.router.navigate(['login']);
			return false;
		}*/
	}
}
