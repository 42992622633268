import { Component, OnInit, Input } from '@angular/core';
import { RuleSet } from '@dashboard/models/rule-set'
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { Rule } from '@dashboard/models/rule'
import { AppService } from '@core/services/app.service'
import { RuleSetService } from '@dashboard/services/rule-set.service'

@Component({
  selector: 'app-import-rule-set',
  templateUrl: './import-rule-set.component.html',
  styleUrls: ['./import-rule-set.component.scss']
})
export class ImportRuleSetComponent implements OnInit {
  
  @Input() ruleSet:RuleSet;
  @Input() importedRuleSet:RuleSet;
  error:string = "";
  errorGet:string = "";
  importedRuleList = [];
  ruleOrderArr:string[] = [];
  constructor(private modalService: NgbModal, private appService:AppService, private ruleSetService:RuleSetService) { }

  ngOnInit(): void {

    for(let i=0; i < this.importedRuleSet.ruleList.length; i++){
      this.importedRuleList.push({"selected":true, "rule": this.importedRuleSet.ruleList[i]})
    }

    this.ruleOrderArr = this.importedRuleSet.ruleOrder.split(",");
  }

  cancel(){
    this.modalService.dismissAll();
  }

  accept(){
    this.ruleOrderArr = [];

    for(let i=0; i < this.importedRuleList.length; i++){
      if(this.importedRuleList[i].selected)
      {
        let importRule:Rule = this.importedRuleList[i].rule;
        importRule.pendingToSave = true;

        let ruleName:string = importRule.name;
        let rule = this.existsRule(ruleName);

        if(rule instanceof Rule)
        {          
          
          if(confirm("Rule '" + ruleName + "' already exits. Are you sure you want replace it?"))                                  
            //this.appService.deepCopy(importRule, rule);
            this.ruleSetService.cloneRule(importRule, rule);
          
        }
        else
        {
          this.ruleSet.ruleList.push(this.importedRuleList[i].rule);
          this.ruleOrderArr.push(ruleName)
        }

      }
    }    

    if(this.ruleOrderArr.length > 0)
      this.ruleSet.ruleOrder = this.ruleSet.ruleOrder + "," + this.ruleOrderArr.join(",");

    this.modalService.dismissAll();
  }

  existsRule(ruleName:string){

    for(let i=0; i < this.ruleSet.ruleList.length; i++){
      if(this.ruleSet.ruleList[i].name == ruleName)
        return this.ruleSet.ruleList[i];
    }

    return false;
  }

  changeSelRule(){    
    
  }

  clickRule(importedRule){
    if(importedRule.selected)
      importedRule.selected = false;
    else
      importedRule.selected = true;

      this.changeSelRule();
  }
}
