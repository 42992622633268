import { Component, OnInit, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { DictionaryService } from '@dashboard/services/dictionary.service'
import { AppService } from '@core/services/app.service'

@Component({
  selector: 'dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss']
})
export class DictionaryComponent implements OnInit {

  message:string = ""
  error:string = ""
  updateError:string = ""
  messageLists:string = ""
  errorLists:string = ""
  updateErrorLists:string = ""
  editorOptions: JsonEditorOptions
  editorOptions2: JsonEditorOptions
  changed: boolean = false
  changedLists: boolean = false
  dictionary: any = {}
  fileName: string = ""
  actions: any = {}
  lists: any = {}

  @ViewChild("JsonEditorComponent1", { static: false }) editorActions: JsonEditorComponent;
  @ViewChild("JsonEditorComponent2", { static: false }) editorLists: JsonEditorComponent;

  constructor(private dictionaryService:DictionaryService, private appService:AppService) { 
    
  }

  ngOnInit(): void {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    //this.options.mode = 'code'; //set only one mode
    
    this.editorOptions2 = new JsonEditorOptions()
    this.editorOptions2.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    //this.options.mode = 'code'; //set only one mode

    this.loadDictionary();      

  }

  loadDictionary(){
    
    this.error = "";

    this.dictionaryService.getActions().subscribe(data => {
               
      this.actions = data;

    },(error)=>{      
      this.error = "An error has occurred. Try again ";
    });

    this.errorLists = "";
    
    this.dictionaryService.getListsOLD().subscribe(data => {
               
      this.lists = data;

    },(error)=>{      
      this.errorLists = "An error has occurred. Try again ";
    });
  }

  loadDictionaryFromDictionary(dictionary:any){

    this.actions.action = dictionary.action;
    this.lists.list = dictionary.list;

    this.editorActions.set(this.actions);
    this.editorLists.set(this.lists);

  }

  getData($event){            
    this.changed = true;
  }

  getData2($event){    
    this.changedLists = true;
  }

  saveActions(){
    
    this.error = "";
            
    this.dictionaryService.updateActions(this.editorActions.get()).subscribe((res) => {
      
      this.changed = false; 

    },(error)=>{
      
      this.error = "An error has occurred. Try again ";
    });
        
    //this.editor.set(this.dictionary);
  }

  saveLists(){
    
    this.errorLists = "";

    this.dictionaryService.updateListsOLD(this.editorLists.get()).subscribe((res) => {
      
      this.changedLists = false; 

    },(error)=>{
      
      this.errorLists = "An error has occurred. Try again ";
    });
        
    //this.editor.set(this.dictionary);
  }

  import(evt): void {

    this.fileName = evt.target.files[0].name;
    console.log(this.fileName)
        
    let file = evt.target.files[0];

    let fileReader: FileReader = new FileReader();
    let self = this;

    fileReader.onloadend = function(x) {      
      let res:string = fileReader.result as string;
      try{

        let obj: any = JSON.parse(res);
        self.dictionary = obj;
        //self.editor.set(self.dictionary);

      }catch(e){console.log(e); self.error = "Incorrect File json format"}
    }
    fileReader.readAsText(file);
  }

  export(){
        
    let text:string = JSON.stringify(this.dictionary, null, 2);
    let filename:string = "dictionary.json";
    this.appService.saveFile(text, filename);

  }

}
