<div id="popupAttributeContainer" [style.left.px]="position.left" [style.top.px]="position.top">
  <div class="container-fluid">
      <div class="row">
          <div class="col">
            <input type="text" #inputValue (keypress)="keyPressNumbers($event)" style="width: 95%;border: 1px solid #ccc; text-align:center" 
                [(ngModel)]="element"/>
          </div>        
      </div>
  </div>
  <div *ngIf="error" class="alert alert-danger" role="alert">
      {{error}}
  </div>
  <div *ngIf="createMode">
      <!--<div *ngFor="let attr of attributes" class="attribute" (click)="selectAttr(attr)" [style.background]="color"
      [ngStyle]="{'border-color':selAttr === attr ? '#0086c0' : '#fff' }" [ngClass]="{'selected': selAttr === attr}">
          {{attr.name}}
      </div>-->
  </div>
      
  <!--<input type="number" #inputValue (keypress)="keyPressNumbers($event)" style="width: 95%;border: 1px solid #ccc; text-align:center" 
  [(ngModel)]="attrCapacity.capacity"/>-->
  
</div>

