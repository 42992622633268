<header>
    <img src="https://www.convergeone.com/hubfs/Convergeone_September2017_Theme/Images/C1-logo_35H.png"/> 
    <span style="display: inline-block;color: #fff;margin-left: 10px;font-weight: 700;vertical-align: middle;">Conversations Rules Designer</span>
    <!--<ul class="menu">
      <li class="dropdown">
        <a class="dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-bars" aria-hidden="true" style="font-size: 20px;"></i>
        </a>    
    
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">      
          <a class="dropdown-item" routerLink="/dashboard/config">Configuration</a>
          <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
        </div>
      </li>
    </ul>-->
  </header>
  <div class="left-menu">
    <ul>

      <li>
        <a routerLink="/dashboard/rules-designer" routerLinkActive="selected" title="Rules designer">
          <i class="fa fa-wpforms" aria-hidden="true"></i>
        </a>
      </li>      

      <li>
        <a routerLink="/dashboard/templates" routerLinkActive="selected" title="Templates">
          <i class="fa fa-file" aria-hidden="true"></i>
        </a>
      </li>
  
      <li>
        <a routerLink="/dashboard/configuration" routerLinkActive="selected" title="Configuration">
          <i class="fa fa-cog" aria-hidden="true"></i>
        </a>
      </li>

    </ul>
  </div>
  
  <div class="container-fluid">
    <div class="wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
  